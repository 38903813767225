// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import cn from './locales/cn.json';


const mapLanguage = (lang) => {
  console.log("browser language", lang);
  switch(lang) {
    case "zh-CN":
      return "cn";
    default:
      return "en";
  }
}

const getUserLanguage = () => {
  let lang = localStorage.getItem("lang");
  if (lang !== null) {
     console.log("Use localStorage lang", lang);
     return lang;
  }
  
  lang = mapLanguage(navigator.language || navigator.languages[0]);
  console.log("Use browser lang", lang)
  return lang;
}

const userLanguage = getUserLanguage();
// const userLanguage = 'en'
console.log("userLanguage", userLanguage)

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      cn: {
        translation: cn
      }
    },
    lng: userLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

console.log("set lang", userLanguage);

export default i18n;

