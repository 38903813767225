import {
    Box,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectToeflData } from "redux/toeflSlice";

export default function Mindmap() {
    const toeflData = useSelector(selectToeflData);
    const message2 = toeflData.content.message2 ? JSON.parse(toeflData.content.message2) : {};
    const message4 = toeflData.content.message4 ? JSON.parse(toeflData.content.message4) : {};

    // Extracting mindmap and keywords from the parsed JSON
    const mindmap = message4.mindmap || {};
    const keywords = message4.keywords || [];
    const revisedEssay = message2.revised_essay || "";

    return (
        <Box padding="3rem">
            <Heading size="lg" mb={4} fontFamily="monospace" fontWeight="bold" color="blue.700">
                Mindmap
            </Heading>

            {/* Mind Map Section */}
            <section>
                <Heading as="h2" size="md" mb={2} fontFamily="monospace">
                    Introduction
                </Heading>
                <UnorderedList>
                    <ListItem>{mindmap.Title}</ListItem>
                    <ListItem>{mindmap.Introduction?.Background?.[0]}</ListItem>
                    <ListItem>{mindmap.Introduction?.Background?.[1]}</ListItem>
                </UnorderedList>

                <Heading
                    as="h2"
                    size="md"
                    mt={4}
                    mb={2}
                    fontFamily="monospace"
                >
                    Body
                </Heading>
                <Table variant="striped" colorScheme="blue" size="sm">
                    <Thead>
                        <Tr>
                            <Th>Theory</Th>
                            <Th>Reading</Th>
                            <Th>Listening</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.entries(mindmap.Body || {}).map(([theory, content]) => (
                            <Tr key={theory}>
                                <Td>{theory}</Td>
                                <Td>{content?.Reading?.join(", ")}</Td>
                                <Td>{content?.Lecture?.join(", ")}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </section>

            <Heading as="h2" size="md" mt={4} mb={2} fontFamily="monospace">
                Conclusion
            </Heading>
            <UnorderedList>
                {mindmap.Conclusion?.Summary?.map((point, index) => (
                    <ListItem key={index}>{point}</ListItem>
                ))}
            </UnorderedList>

            {/* Keywords Section */}
            <Heading size="lg" mt={4} mb={2} fontFamily="monospace" fontWeight="bold" color="blue.700">
                Keywords
            </Heading>

            <Table variant="striped" colorScheme="blue" size="sm">
                <Thead>
                    <Tr>
                        <Th>WORD</Th>
                        <Th>PHONETIC SYMBOL</Th>
                        <Th>PART OF SPEECH</Th>
                        <Th>ENGLISH DEFINITION</Th>
                        <Th>SAMPLE SENTENCE</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {keywords.map((keyword, index) => (
                        <Tr key={index}>
                            <Td>{keyword.word}</Td>
                            <Td>{keyword.phonetic_symbol}</Td>
                            <Td>{keyword.part_of_speech}</Td>
                            <Td>{keyword.english_definition}</Td>
                            <Td>{keyword.sample_sentence}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Displaying Revised Essay */}
            <Heading size="lg" mt={4} mb={2} fontFamily="monospace" color="blue.700" fontWeight="bold">
                Revised Essay
            </Heading>
            <Text>{revisedEssay}</Text>
        </Box>
    );
}
