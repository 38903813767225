import { Button, Center, Divider, Flex, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import { useGetQuestionListQuery } from "../services/cmApi";
import React, { useState } from "react";
import { QuestionCard } from "./QuestionCard";
import TaskFilterRow from "./TaskFilterRow";
import { getGenTypesForQuestionList } from "./utils/common";


export default function QuestionListPanel(props) {
    var { test, section, questionSet, tags, hideTags, ...other } = props;
    const columnsValue = useBreakpointValue({ base: 1, md: 2, xl: 2 });
    const { t, i18n } = useTranslation();
    let gen_types = getGenTypesForQuestionList(test, section);
    const [selectedGenType, setSelectedGenType] = useState(gen_types[0]);
    const [completionStatus, setCompletionStatus] = useState('all');
    let questionList = useGetQuestionListQuery({
        question_set: questionSet,
        gen_types: [selectedGenType],
        completion_status: completionStatus,
        lang: i18n.language,
        tags: tags,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = useBreakpointValue({ base: 4, md: 10, xl: 10 });

    const getQuestionListBody = () => {
        if (questionList.status !== 'fulfilled') {
            return null;
        }
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        return <>
            <SimpleGrid columns={columnsValue} spacing="10px" gap="10px">
                {questionList.data.data.slice(startIndex, endIndex).map((item, index) => {
                    return <>
                        <Center>
                            <QuestionCard
                                genType={item.gen_type}
                                tpoNumber={item.tpo_number}
                                title={`${item.title} ${t(item.gen_type)}`}
                                summary={item.summary}
                                tags={hideTags ? [] : item.tags}
                                qid={item.qid}
                                completed={item.completed}
                            ></QuestionCard>
                        </Center>
                    </>
                })}
            </SimpleGrid>
        </>
    }

    const totalPages = questionList.data ? Math.ceil(questionList.data.data.length / itemsPerPage) : 0;

    const paginationControls = (
        <Flex mt={4} justifyContent="center">
            <Button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
            >
                ←
            </Button>
            <Center>
                <Text mx={4}>
                    {currentPage}/ {totalPages}
                </Text>
            </Center>
            <Button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
            >
                →
            </Button>
        </Flex>
    );

    return <>
        <Flex mx="40px" flexDirection="column" gap="5px">
            <TaskFilterRow
                rowName={t('task') + ":"}
                options={gen_types}
                names={gen_types.map((x) => t(x))}
                selectedOption={selectedGenType}
                onChange={(value) => {
                    setSelectedGenType(value)
                    // dispatch(updatePart(mapIeltsPartIndex(value, section)));
                    setCurrentPage(1);
                }}
            > </TaskFilterRow>
            <TaskFilterRow
                rowName={t('completion_status') + ":"}
                options={['all', 'completed', 'not_completed']}
                selectedOption={completionStatus}
                names={[t("all"), t("completed"), t("not_completed")]}
                onChange={(value) => {
                    setCompletionStatus(value)
                    setCurrentPage(1);
                }}
            > </TaskFilterRow>
        </Flex>

        <Divider my="26px" mx="20px" />
        <Flex mt={5}>
            {getQuestionListBody()}
        </Flex>
        {paginationControls}

    </>

}