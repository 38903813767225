import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
    });
};

const login = (email, phone, password) => {
    return axios
        .post(API_URL + '/api/login', {
            username:email,
            password:password,
            phone_number:phone,
        })
        .then((response) => {
            console.log("Login");
            if (response.data.user_id) {
                // Set token valid time to be slightly shorter than server side
                let futureDate = new Date();
                futureDate.setDate(futureDate.getDate() + 5);
                response.data.expiration_time = futureDate.getTime();
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
}

const logout = () => {
    let headers = authHeader();
    localStorage.removeItem("user");
    return axios
        .get(API_URL + '/api/logout', {headers: headers})
        .then((response) => {
            console.log("Logged out: ", response)
        });
};

const isLoggedIn = (currentUser) => {
    if (currentUser === null) {
        return false;
    }

    // Check if the expiration_time key exists in the userInfo
    if (!('expiration_time' in currentUser)) {
        console.error('expiration_time not found in user info');
        return false;
    }

    const currentTime = new Date().getTime();
    if (currentTime > currentUser.expiration_time) {
        console.error('User info has expired');
        return false;
    }

    return true;
}

const getMyInfo = () => {
    let headers = authHeader();
    return axios
        .post(API_URL + '/api/me', {}, {headers: headers})
        .then((response) => {
            console.log("getMyInfo");
            if (response.data.user_id) {
                // Set token valid time to be slightly shorter than server side
                let futureDate = new Date();
                futureDate.setDate(futureDate.getDate() + 5);
                response.data.expiration_time = futureDate.getTime();
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
}

const getCurrentUser = () => {
    const localUser = JSON.parse(localStorage.getItem("user"));
    if (!isLoggedIn(localUser)) {
        localStorage.removeItem("user");
        return null;
    }
    return localUser;
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    getMyInfo,
};

export default AuthService;
