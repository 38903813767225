import React, { Suspense, lazy } from 'react';
import ReactDOM from "react-dom";
import {HashRouter, Route, Switch, Redirect} from "react-router-dom";
import Register from "./Register";

import AuthLayout from "layouts/Auth.js";
import DashboardLayout from "layouts/Page.js";
import {ChakraProvider} from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import {Provider} from 'react-redux';
import {store} from './app/store';
import './i18n';
import {BrowserRouter as Router} from "react-router-dom";
import Login from "./Login";
import LoginSuccess from "./LoginSuccess";
// import SpeedTest from "./views/Pages/SpeedTest"; 

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Home from "./Home";
import Root from "./Root";
import {TPOPracticePage} from "./pagecomponents/TPOPracticePage";
import {PasswordResetRequestPage} from "./pagecomponents/PasswordResetRequestPage";
import {PasswordResetPage} from "./pagecomponents/PasswordResetPage";
import ShortcutPurchase from "./ShortcutPurchase";
import mixpanel from 'mixpanel-browser';
import PageViewTracker from './components/PageViewTracker';  // Import the PageViewTracker


const SpeedTest = lazy(() => import("./views/Pages/SpeedTest"));
mixpanel.init('3724690ef07434885ea1e0382fbdd5ed', {
    debug: false,
    ignore_dnt: true
});


ReactDOM.render(
    <ChakraProvider theme={theme} resetCss={false} position="relative">
        <Provider store={store}>
            <Router>
                <PageViewTracker />
                <Switch>
                    <Route path="/register">
                        <Register/>
                    </Route>
                    <Route path="/login" component={Login}/>
                    <Route path="/speed_test">
                    <Suspense fallback={<div>Loading...</div>}>
                        <SpeedTest />
                    </Suspense>
                    </Route>
                    <Route path="/reset_password_request">
                        <PasswordResetRequestPage/>
                    </Route>
                    <Route path="/reset_password">
                        <PasswordResetPage/>
                    </Route>
                    <Route path="/set_password">
                        <PasswordResetPage isSetPassword={true}/>
                    </Route>
                    <Route path={`/login`} component={Login}/>
                    <Route path={`/login_success`} component={LoginSuccess}/>
                    <Route path={`/trial`} component={ShortcutPurchase}/>
                    <Route path={`/auth`} component={AuthLayout}/>
                    <Route path={`/toefl`} component={DashboardLayout}/>
                    <Route path={`/ielts`} component={DashboardLayout}/>
                    <AuthenticatedRoute path="/admin" component={DashboardLayout}/>
                    <Route path={`/purchase`} component={DashboardLayout}/>
                    <Route path={`/ps_assistant`} component={DashboardLayout}/>
                    <Redirect from="/personal_statement_assistant" to="/ps_assistant"/>
                    <Route path={`/account`} component={DashboardLayout}/>
                    <Route from={`/share/:gid`} component={DashboardLayout}/>
                    <AuthenticatedRoute path="/mock_test" component={TPOPracticePage}/>
                    <Route path="/campus_certification" component={DashboardLayout}/>
                    <Route path="/campus_certification_guidance" component={DashboardLayout}/>
                    <Route path="/">
                        <Root/>
                    </Route>
                </Switch>
            </Router>
        </Provider>
    </ChakraProvider>,
    document.getElementById("root")
);
