import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    FormControl,
    HStack,
    Image,
    Input,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast
} from '@chakra-ui/react'
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {PasswordField} from './components/PasswordField'
import {useLoginMutation} from "./services/cmApi";
import AuthService from "./services/auth.service";
import axios from "axios";
import logoPng from "./assets/logo.png";
import {selectToeflData} from "redux/toeflSlice";
import {OAuthButtonGroup} from "./components/OAuthButtonGroup";
import fullScoreTemplatePng from "./assets/img/full_score_template.png";
import aiEvaluationPng from "./assets/img/ai_evaluation.png";
import examTypesPng from "./assets/img/exam_types.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import mixpanel from 'mixpanel-browser';

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export const Login = (props) => {
    const toeflData = useSelector(selectToeflData);
    const currentUser = AuthService.getCurrentUser();
    let history = useHistory();
    if (currentUser) {
        history.push('/toefl/speaking')
        return null;
    }
    console.log(toeflData.selectedProduct, "toeflProduct")
    const {t, i18n} = useTranslation();
    console.log("i18n language", i18n.language)
    // const [username, setUsername] = useState("");
    const [showEmailVerified, setShowEmailVerified] = useState(false);
    const [loginRequest, isLoginError] = useLoginMutation();
    const [pendingVerification, setPendingVerification] = useState(false);
    const toast = useToast();
    const query = useQuery();
    const location = useLocation();
    const {state} = location;
    const [defaultIdx, setDefaultIdx] = useState(parseInt(state?.defaultIdx || 0));
    const [email, setEmail] = useState(state?.email || "");
    const [phone, setPhone] = useState(state?.phone || "");

    useEffect(() => {
        if (query.get('verify_email_success') === 'true') {
            setShowEmailVerified(true)
            toast({
                title: t("verify_success_title"),
                description: t("verify_success_description"),
                status: "info",
                duration: 2500,
                isClosable: true,
            });
        }
    }, []);  // <-- empty dependency array to run only once on mount

    const onResendCode = () => {
        axios.post(API_URL + '/api/resend_verification_email', {email: username})
            .then(response => {
                console.log(response);
                if (response.data.error) {
                    toast({
                        title: t("resend_toast_title"),
                        description: response.data.error,
                        status: "error",
                        duration: 2500,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: t("resend_toast_title"),
                        description: t("resend_success_description"),
                        status: "success",
                        duration: 2500,
                        isClosable: true,
                    });
                }
            })
            .catch(error => {
                console.error('There was an error fetching line items!', error);
            });
    }

    function createInputField(userType) {
        const [password, setPassword] = useState("");
        return (
            <Stack spacing="2">
                <FormControl>
                    {userType === "email" ? (
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={t("email")}
                            style={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                display: "block",
                                width: "100%",
                                padding: "8px 0",
                                color: "#999",
                                fontSize: "16px",
                                outline: "none",
                                transition: "border-color 0.3s ease",
                                borderRadius: 0,
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    ) : (
                        <PhoneInput
                            name="phone"
                            id="phone"
                            international
                            country={'us'}
                            preferredCountries={['us', 'cn']}
                            onChange={(e) => {
                                console.log("+" + e)
                                setPhone("+" + e)
                            }}
                            buttonStyle={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                            }}
                            inputStyle={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                display: "block",
                                width: "100%",
                                height: "40px",
                                color: "#999",
                                fontSize: "16px",
                                outline: "none",
                                transition: "border-color 0.3s ease",
                                borderRadius: 0,
                            }}
                            value={phone}
                        />
                    )}
                </FormControl>
                <PasswordField onChange={(e) => setPassword(e.target.value)} value={password}/>
                <HStack justify="space-between">
                    <HStack spacing="1">
                        <Text
                            color="muted"
                            style={{
                                fontSize: "14px", // 减小字体大小
                                marginBottom: "0px", // 移除底部外边距
                            }}
                        >
                            {t("Not a registered user yet")}
                        </Text>
                        <Button
                            variant="link"
                            colorScheme="blue"
                            onClick={() => {
                                const location = {
                                    pathname: '/register',
                                    state: {defaultIdx, phone, email, password}
                                };
                                history.push(location);
                            }}
                            style={{
                                fontSize: "14px", // 减小字体大小
                            }}
                        >
                            {t("sign_up")}>
                        </Button>
                    </HStack>
                    <Button variant="link" colorScheme="blue" size="sm"
                            onClick={() => {
                                const location = {
                                    pathname: '/reset_password_request',
                                    state: {defaultIdx, phone, email, password}
                                };
                                history.push(location);
                            }}
                    >
                        {t("forgot_password")}?
                    </Button>
                </HStack>
                <Box height={'10px'}></Box>
                <Button colorScheme="blue"
                        onClick={async () => {
                            AuthService.login(email, phone, password).then(
                                () => {
                                    const user = JSON.parse(localStorage.getItem('user'))
                                    mixpanel.identify(user.uid);
                                    mixpanel.people.set({
                                        $email: email,
                                        $phone: phone,
                                        $name: user.user_name,
                                        source: "regular_login",
                                    });
                                    const {from} = props.location.state || {from: {pathname: `/${toeflData.selectedProduct ? toeflData.selectedProduct : "toefl"}/speaking`}};
                                    history.push(from);
                                },
                                (error) => {
                                    console.log(error);
                                    toast({
                                        // title: t("login_error"),
                                        description: error.response.status != 401 ? error.message : error.response.data.error,
                                        status: "error",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    //console.log(error.message)
                                    if (error.response.data.code == "error_login_user_not_verified") {
                                        //console.log("setPendingVerification")
                                        setPendingVerification(true);
                                    }
                                    console.log("login error")
                                    console.log(error);
                                }
                            );
                        }}
                >{t("login")}</Button>
                <HStack>
                    <Divider/>
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                        Or
                    </Text>
                    <Divider/>
                </HStack>
                <OAuthButtonGroup/>
            </Stack>
        )
    }


    return (
        <Container maxWidth="2000px"
                   maxHeight="100%"
                   maxW="lg"
                   py={{base: '12', md: '24'}}
                   px={{base: '0', sm: '8'}}
                   minH="100vh"
                   display="flex"
                   justifyContent="center"
                   alignItems="center">
            <Stack>
                <Flex justifyContent="center" alignItems="center">
                    <Flex alignItems="center">
                        <Image className="circlex-logo" width={50} height={50} src={logoPng}/>
                        <Box width={'10px'}></Box>
                        <span className="ml-2"
                              style={{
                                  fontWeight: 'bold',
                                  letterSpacing: '1px',
                                  fontSize: '30px'
                              }}>LINGOLEAP {t('An AI English test preparation platform from Silicon Valley')}</span>
                    </Flex>
                </Flex>
                <Box height={"50px"}></Box>
                <Flex justifyContent="center" alignItems="center">
                    <Flex>
                        <Flex direction={'column'} style={{maxWidth: '580px', minWidth: '240px'}}>
                            <Text style={{
                                fontWeight: 'bold',
                                fontSize: '25px',
                                letterSpacing: '1px'
                            }}>{t("your personal test prep assistant")}</Text>
                            <Box height={'30px'}></Box>
                            <Flex>
                                <Flex alignItems="center">
                                    <Image width={25} height={25} src={fullScoreTemplatePng}/>
                                    <Box width={"20px"}></Box>
                                    <Text style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        letterSpacing: '1px'
                                    }}>{t("automatically generate a perfect score template")}</Text>
                                </Flex>
                            </Flex>
                            <Box height={'15px'}></Box>
                            <Flex>
                                <Flex alignItems="center">
                                    <Image width={25} height={25} src={aiEvaluationPng}/>
                                    <Box width={"20px"}></Box>
                                    <Text style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        letterSpacing: '1px'
                                    }}>{t("AI automatically corrects and gives guidance and suggestions")}</Text>
                                </Flex>
                            </Flex>
                            <Box height={'15px'}></Box>
                            <Flex>
                                <Flex alignItems="center">
                                    <Image width={25} height={25} src={examTypesPng}/>
                                    <Box width={"20px"}></Box>
                                    <Text style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        letterSpacing: '1px'
                                    }}>{t("TOEFL IELTS all round support")}</Text>
                                </Flex>
                            </Flex>
                            <Box height={'120px'}></Box>
                        </Flex>
                    </Flex>
                    <Box width={"80px"}></Box>
                    <Box
                        py={{base: '0', sm: '8'}}
                        px={{base: '4', sm: '10'}}
                        bg={{base: 'transparent', sm: 'bg-surface'}}
                        boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
                        borderRadius={{base: 'none', sm: 'xl'}}
                        width={"400px"}
                    >
                        <Tabs
                            isFitted variant='enclosed'
                            defaultIndex={defaultIdx}
                            onChange={(index) => {
                                setDefaultIdx(index);
                            }}>
                            <TabList mb='1em'>
                                <Tab>{t("Phone Number")}</Tab>
                                <Tab>{t("email")}</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel sx={{padding: 0}}>
                                    <div>
                                        {createInputField("phone")}
                                    </div>
                                </TabPanel>
                                <TabPanel sx={{padding: 0}}>
                                    <div>
                                        {createInputField('email')}
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>
                <Box height={"100px"}></Box>
            </Stack>
        </Container>
    );
}

export default Login;
