import Card from "./Card/Card";
import { Button, Text, Box } from "@chakra-ui/react";
import { AiOutlineMessage } from "react-icons/ai";
import React, { useState, useEffect, useRef } from 'react';
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { EditorState } from "prosemirror-state";
import { RiFireFill } from "react-icons/ri";
import { CiStreamOn } from "react-icons/ci";
import { TfiWrite } from "react-icons/tfi";
import { FaImage } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { useSelector } from "react-redux";
import { selectToeflData } from "redux/toeflSlice";
import { schema as basicSchema } from 'prosemirror-schema-basic';
import { AnimatePresence, motion } from "framer-motion";
import { VscCircleFilled } from "react-icons/vsc";

import '../editor.css';

const annotationMark = {
    attrs: { title: { default: "" }, id: { default: "" }, type: { default: "" } },
    inclusive: false,
    parseDOM: [
        { tag: "span.annotation", getAttrs: dom => ({ title: dom.getAttribute("title"), id: dom.getAttribute("data-id"), type: dom.getAttribute("data-type") }) }
    ],
    toDOM: node => {
        return ["span", { class: `annotation ${node.attrs.type}`, title: node.attrs.title, "data-id": node.attrs.id }, 0];
    }
};

const mySchema = new Schema({
    nodes: basicSchema.spec.nodes,
    marks: {
        ...basicSchema.spec.marks,
        annotation: annotationMark
    }
});

const EssayComments = () => {
    const editorRef = useRef();
    const [annotations, setAnnotations] = useState([]);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);
    const [words, setWordCount] = useState("");
    const [sentences, setSentenceCount]=useState("");
    const toeflData = useSelector(selectToeflData);
    const readingText = toeflData.readingText;
    const [message3, setMessage3] = useState(toeflData.content.message3);

    useEffect(() => {
        setMessage3(toeflData.content.message3 ? JSON.parse(toeflData.content.message3) : toeflData.content.message3);
    }, [toeflData.content.message3]);

    useEffect(() => {
        const annotationText = document.getElementById('annotationText').innerText;
        const words = annotationText.split(/\s+/); // Split by whitespace characters
        setWordCount(words.length);
        const sentences = annotationText.split(/[.!?]+/); // Split by sentence-ending punctuation marks
        setSentenceCount(sentences.length - 1);
        const startDoc = mySchema.nodeFromJSON({
            type: "doc",
            content: [{
                type: "paragraph",
                content: [{
                    type: "text",
                    text: annotationText,
                }]
            }]
        });

        const state = EditorState.create({
            doc: startDoc,
            schema: mySchema
        });

        const view = new EditorView(editorRef.current, {
            state,
            dispatchTransaction(transaction) {
                let newState = view.state.apply(transaction);
                view.updateState(newState);
            }
        });

        view.dom.addEventListener('click', (event) => {
            const { pos } = view.posAtCoords({ left: event.clientX, top: event.clientY });
            const resolvedPos = view.state.doc.resolve(pos);
            const marks = resolvedPos.marks();

            const annotation = marks.find(mark => mark.type === mySchema.marks.annotation);
            if (annotation) {
                handleCommentClick(annotation.attrs.id);
                scrollToComment(annotation.attrs.id);
            }
        });

        initAnnotations(view);

        return () => {
            view.destroy();
        };
    }, [message3]);

    const initAnnotations = (view) => {
        const initialAnnotations = [];

        if (message3?.grammar_errors) {
            const grammarErrorsAnnotations = message3.grammar_errors.map((error) => ({
                type: 'grammarError',
                color: 'yellow',
                word: error.original_text,
                suggestion: { annotation: error.corrected_text, desc: error.explanation }
            }));
            initialAnnotations.push(...grammarErrorsAnnotations);
        }

        if (message3?.spelling_errors) {
            const spellingErrorsAnnotations = message3.spelling_errors.map((error) => ({
                type: 'spellingError',
                color: 'green',
                word: error.original_text,
                suggestion: { annotation: error.corrected_text, desc: error.explanation }
            }));
            initialAnnotations.push(...spellingErrorsAnnotations);
        }

        if (message3?.rephrases) {
            const rephrasesAnnotations = message3.rephrases.map((rephrase) => ({
                type: 'rephrase',
                color: 'red',
                word: rephrase.original_text,
                suggestion: { annotation: rephrase.corrected_text, desc: rephrase.explanation }
            }));
            initialAnnotations.push(...rephrasesAnnotations);
        }

        initialAnnotations.forEach(({ word, suggestion, type }, index) => {
            addAnnotation(view.state, view.dispatch, word, suggestion.annotation, type, index.toString());
        });

        setAnnotations(initialAnnotations.map((a, index) => ({ ...a, id: index.toString() })));
    };

    const addAnnotation = (state, dispatch, word, annotationText, type, id) => {
        let tr = state.tr;
        let regex = new RegExp(`\\b${word}\\b`, "g");

        state.doc.descendants((node, pos) => {
            if (!node.isText) return;

            let text = node.text;
            let matches;
            while ((matches = regex.exec(text)) !== null) {
                let from = pos + matches.index;
                let to = from + word.length;
                tr = tr.addMark(from, to, mySchema.marks.annotation.create({ title: annotationText, id, type }));
            }
        });

        if (tr.docChanged) {
            dispatch(tr);
        }
    };

    const handleCommentClick = (id) => {
        setSelectedAnnotationId(id);
    };

    const scrollToComment = (id) => {
        const commentElement = document.getElementById(`comment-${id}`);
        if (commentElement) {
            commentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <Box display="flex" flexDirection="column" gap="12px" width="auto" bgColor="white" padding="3rem">
            <Box experimental_spaceY="12px">
                {/*<Box width="600px" display="flex" flexDirection="column" rowGap="12px" borderRadius="0px">*/}
                {/*    <Text fontWeight="bold"> Question (topic)</Text>*/}
                {/*    <Box display="flex" flexDirection="row" flexWrap="wrap" maxW="600px" gap="12px" color="blue.600">*/}
                {/*        <Button height="2rem" borderRadius="full" bgColor="blue.100" gap="3px"><RiFireFill color="orange" />New Topics</Button>*/}
                {/*        <Button height="2rem" borderRadius="full" bgColor="blue.100" gap="3px"><CiStreamOn color="blue" />Random Topics</Button>*/}
                {/*        <Button height="2rem" borderRadius="full" bgColor="blue.100">🤔 Generate viewpoint</Button>*/}
                {/*        <Button height="2rem" borderRadius="full" bgColor="blue.100" gap="3px"><TfiWrite color="blue" />Generate outline</Button>*/}
                {/*    </Box>*/}
                {/*    <Box>*/}
                {/*        <Text >*/}
                {/*            {readingText}*/}
                {/*        </Text>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <Box width="auto" display="flex" flexDirection="column" rowGap="12px" borderRadius="0px">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold"> Essay</Text>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                            <Box borderRadius="full" bgColor="gray.100" padding="10px">{words} words</Box>
                            <Box borderRadius="full" bgColor="gray.100" padding="10px">{sentences} sentences</Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" maxW="600px" gap="12px" color="blue.600" fontWeight="bold">

                        <Text display="flex" alignItems="center" gap="10px" color="yellow.400"><VscCircleFilled color="yellow" />Grammar Errors</Text>

                        <Text display="flex" alignItems="center" gap="10px" color="green.400"><VscCircleFilled color="lightgreen" />Spelling Errors</Text>

                        <Text display="flex" alignItems="center" gap="10px" color="red.400"><VscCircleFilled color="" />Rephrases</Text>
                    </Box>
                    <Box>
                        <Text ref={editorRef}>
                            <Text id="annotationText" border="none" hidden>The reading passage notes that how Gamburtsev range originally formed, providing three theories to support this claim. Conversely, the lecturer in the listening contends that all of these theories have weakness.
                                Firstly, the author asserts that the volcanic hotspot located under Earth's crust may created the Gamburtsev range. When the hotspot melt rocks and the surface, the crust move slowly into a range. Nevertheless, the speaker counters this claim by stating that if this theory in correct, the specific hotspot can be detect long after the activity. but the nearest volcano is 5 kilometers away, so it is not near enough for the possible activity. This theory is short of evidence.
                                Secondly, the continental plate collision theory is brought up in the reading material because the collision would lead to the elevating of mountain ranges. And the evidence of Antarctic plate can verify this. On the contrary, the lecturer in the listening disputes this point by arguing that the continental plate collision was 250 million years age which indicated that there must be some evidence to show the age due to the erosion. As the peak of Gamburtsev range is tall and sharp, it means this is a young mountain. Judging the appearance of this range can dispute this theory.
                                Finally, the writer in the reading material believes that the Gamburtsev was formed by ice. The thick ice layer can grind away the rocks and make the crust thinner which was sensitive to the geological forces. On the other hand, the listening challenges the assertion by pointing out that the valley of the range are clear shaped by running waters, which means the mountain was created before all the surface water, the Gamburtsev are covered by ice.</Text>
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap="25px" maxH="72rem" width="1000px" overflowX="auto" paddingLeft="40px">
                {annotations.map(annotation => (
                    <React.Fragment key={annotation.id}>
                        {selectedAnnotationId === annotation.id ? (
                            <Card
                                id={`comment-${annotation.id}`}
                                border="2px"
                                borderColor={`${annotation.color}.400`}
                                minW="300px"
                            >
                                <Text color={`${annotation.color}.600`} fontWeight="bold">{annotation.suggestion.annotation}</Text>
                                <Text>{annotation.suggestion.desc}</Text>
                            </Card>
                        ) : (
                            <Card
                                id={`comment-${annotation.id}`}
                                height="fit"
                                border="2px"
                                borderColor={`${selectedAnnotationId === annotation.id ? annotation.color : "blue.200"}`}
                                rowGap="8px"
                                minW="300px"
                                onClick={() => handleCommentClick(annotation.id)}
                            >
                                <Text>{annotation.suggestion.annotation}</Text>
                                <Text>{annotation.suggestion.desc}</Text>
                            </Card>
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default EssayComments;
