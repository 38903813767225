import React, {useEffect, useState} from "react";
import {
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import {ViewFeedBackPanel} from "./ViewFeedBackPanel";
import authHeader from "../services/auth-header";
import axios from "axios";

export function AdminViewFeedbackPanel(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
            let headers = authHeader();

            const response = await axios.get(API_URL + `/api/users`, { headers: headers });
            setUsers(response.data);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Card
            overflowX={{sm: "scroll", xl: "hidden"}}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Feedback
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                {ViewFeedBackPanel(users)}
            </CardBody>
        </Card>
    );
}