import { createSlice } from '@reduxjs/toolkit';
import { cmApi } from "../services/cmApi";
import AuthService from "../services/auth.service";

const initialState = {
    rightSideContent: "toefl",
    toeflData: {
        tabIndex: 0,
        section: 'speaking',
        sectionUpdatedTimestamp: 0,
        listeningAudioPath: "",
        taskName: "task1",
        completionStatus: "all",
        genType: "",
        tpoNumber: -1,
        bundleName: "",
        readingText: "",
        listeningText: "",
        question: "",
        conversations: "",
        targetScore: "30",
        answerOutline: "",
        answer: "",
        shouldShowMarkdown: false,
        audioFile: "",
        audioSource: "",
        isStreaming: false,
        stream_short_id: null,
        abortController: new AbortController(),
        recordingSeconds: 0,
        intervalId: null,
        // uuid: "",
        gid: "",
        isSelectionDone: false,
        selectedProduct: localStorage.getItem('selectedProduct') || '',
        selectedExampleLevel: "",
        exampleAnswerMap: {},
        content: {},

    },
    markdownResponse: {}
};

export const toeflSlice = createSlice({
    name: 'toefl',
    initialState,
    reducers: {
        updateRightSideContent: (state, action) => {
            state.rightSideContent = action.payload;
        },
        abortGeneration: (state, action) => {
            state.toeflData.abortController.abort();
            state.toeflData.abortController = new AbortController()
            state.toeflData.stream_short_id = null;
            state.toeflData.isStreaming = false;
        },
        appendResponseTrunk: (state, action) => {
            if (!state.markdownResponse.result) {
                state.markdownResponse.result = "";
            }
            if (action.payload.sid !== state.toeflData.stream_short_id) {
                return;
            }

            if (action.payload.c.startsWith('<!-- diff_start -->')) {
                // Special message for diff content. We need to replace all content from
                // `<!-- content_start -->` to here with content after `<!-- diff_start -->`
                let diff = action.payload.c.split('<!-- diff_start -->')[1];
                let result = state.markdownResponse.result
                if (result.includes('<!-- content_start -->')) {
                    // Found special content tag
                    console.log("replacing old content with diff")
                    state.markdownResponse.result = result.split('<!-- content_start -->')[0] + diff
                } else {
                    // No special tag found, can't replace diff
                    console.log("Trying to replacing old content with diff but no <!-- content_start --> found")
                }
            } else {
                // Regular message
                state.markdownResponse.result += action.payload.c.replaceAll("\\n", "\n");
            }

        },
        updateSelection: (state, action) => {
            state.toeflData.isSelectionDone = action.payload;
        },
        updateSelectedProduct: (state, action) => {
            console.log(action.payload)
            state.toeflData.selectedProduct = action.payload;
            localStorage.setItem('selectedProduct', action.payload);
        },
        updateContent: (state, action) => {
            //console.log(action.payload, "check payload");

            // Check if the payload has user_id property
            if (!action.payload.hasOwnProperty('user_id')) {
                // Check if the payload message contains the specified string
                const duplicateMessage = "We're working on a duplicate request you recently submitted. The evaluation result will be available under the history tab. Thank you for your patience!";
                if (action.payload === duplicateMessage) {
                    // Update the content to "Duplicate"
                    state.toeflData.content = "Duplicate";
                } else {
                    // Update the content with the payload
                    state.toeflData.content = action.payload;
                }
            }
        },
        clearResponse: (state, action) => {
            state.markdownResponse = {};
            state.toeflData.shouldShowMarkdown = false;
            state.toeflData.gid = action.payload.gid;
            state.toeflData.stream_short_id = action.payload.sid;
            state.toeflData.selectedExampleLevel = "";
            // state.toeflData.audioFile = "";
        },
        clearAnswer: (state, action) => {
            state.toeflData.readingText = "";
            state.toeflData.listeningText = "";
            state.toeflData.conversations = "";
            state.toeflData.question = "";
            state.toeflData.tpoNumber = -1;
            state.toeflData.listeningAudioPath = "";
            state.toeflData.answer = "";
            state.toeflData.audioFile = "";
        },
        clearQuestionAndAnswer: (state, action) => {

            state.toeflData.section = "";
            state.toeflData.taskName = "";
            state.toeflData.tpoNumber = "";
            state.toeflData.readingText = "";
            state.toeflData.listeningText = "";
            state.toeflData.listeningAudio = null;
            state.toeflData.conversations = null;
            state.toeflData.question = "";
            state.toeflData.listeningAudioPath = "";
            state.toeflData.genType = "";

            state.toeflData.answer = ""
            state.toeflData.audioFile = ""
            state.toeflData.audioSource = ""
            state.toeflData.bundleName = ""

            state.markdownResponse = {};
            state.toeflData.shouldShowMarkdown = false;
            state.toeflData.gid = "";
            state.toeflData.stream_short_id = "";
            state.toeflData.selectedExampleLevel = "";
        },
        handleErrorResponse: (state, action) => {
            if (action.payload.c.sid !== state.toeflData.stream_short_id) {
                return;
            }
            state.markdownResponse.result = action.payload.c.message;
        },
        updateTabIndexNoReset: (state, action) => {
            state.toeflData.tabIndex = action.payload;
        },
        updateTabIndex: (state, action) => {
            state.toeflData.tabIndex = action.payload;
            state.toeflData.taskName = "task1";
            state.toeflData.targetScore = "30";
            state.toeflData.answerOutline = "";

            state.markdownResponse = {};
            state.toeflData.shouldShowMarkdown = false;
            state.toeflData.stream_short_id = null;
            state.toeflData.isStreaming = false;
            state.toeflData.selectedExampleLevel = "";
            state.toeflData.audioFile = "";

            if (state.toeflData.tabIndex != 0) {
                state.toeflData.readingText = "";
                state.toeflData.listeningText = "";
                state.toeflData.conversations = "";
                state.toeflData.question = "";
                state.toeflData.tpoNumber = -1;
                state.toeflData.listeningAudioPath = "";
                state.toeflData.answer = "";
            }
            state.toeflData.sectionUpdatedTimestamp = Math.floor(Date.now());
        },
        updateSection: (state, action) => {
            state.toeflData.section = action.payload;
            state.toeflData.taskName = "task1";
            state.toeflData.targetScore = "30";
            state.toeflData.answerOutline = "";
            state.markdownResponse = {};
            state.toeflData.shouldShowMarkdown = false;
            state.toeflData.stream_short_id = null;
            state.toeflData.isStreaming = false;
            state.toeflData.selectedExampleLevel = "";

            if (state.toeflData.tabIndex != 0) {
                state.toeflData.readingText = "";
                state.toeflData.listeningText = "";
                state.toeflData.conversations = "";
                state.toeflData.question = "";
                state.toeflData.tpoNumber = -1;
                state.toeflData.listeningAudioPath = "";
                state.toeflData.answer = "";
            }

            state.toeflData.sectionUpdatedTimestamp = Math.floor(Date.now());
        },
        changeSection: (state, action) => {
            state.toeflData.section = action.payload;
            state.toeflData.selectedExampleLevel = "";
        },
        loadHistoryTask: (state, action) => {
            const gen_type = action.payload.gen_type;
            const tpo = action.payload.tpo;
            switch (gen_type) {
                case "speaking_task1":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'speaking'
                    state.toeflData.taskName = "task1";
                    break;
                case "speaking_task2":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'speaking'
                    state.toeflData.taskName = "task2";
                    break;
                case "speaking_task3":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'speaking'
                    state.toeflData.taskName = "task3";
                    break;
                case "speaking_task4":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'speaking'
                    state.toeflData.taskName = "task4";
                    break;
                case "speaking_generation":
                    state.toeflData.tabIndex = 2; // Generation
                    state.toeflData.section = 'speaking'
                    state.toeflData.taskName = "";
                    break;
                case "writing_task1":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'writing'
                    state.toeflData.taskName = "task1";
                    break;
                case "writing_task2":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'writing'
                    state.toeflData.taskName = "task2";
                    break;
                case "writing_task3":
                    state.toeflData.tabIndex = tpo ? 0 : 1;
                    state.toeflData.section = 'writing'
                    state.toeflData.taskName = "task3";
                    break;
                case "writing_generation":
                    state.toeflData.tabIndex = 2
                    state.toeflData.section = 'writing'
                    state.toeflData.taskName = "";
                    break;
                default:
                    state.toeflData.tabIndex = 0;
                    state.toeflData.section = 'speaking'
                    state.toeflData.taskName = "task1";
                    break;
            }

            state.toeflData.readingText = action.payload.reading;
            state.toeflData.listeningText = action.payload.listening;
            state.toeflData.conversations = action.payload.conversations;
            state.toeflData.targetScore = action.payload.target_score;
            state.toeflData.question = action.payload.topic;
            state.toeflData.answer = action.payload.content;
            state.toeflData.answerOutline = action.payload.outline;
            state.toeflData.shouldShowMarkdown = false;
            state.toeflData.audioFile = "";
            state.toeflData.gid = "";
            state.toeflData.tpo = tpo
            state.toeflData.tpoNumber = tpo
        },
        updateToelfGid: (state, action) => {
            state.toeflData.gid = action.payload;
        },
        updateTpoNumber: (state, action) => {
            state.toeflData.tpoNumber = action.payload;
        },
        loadTpo: (state, action) => {
            state.toeflData.section = action.payload.section;
            state.toeflData.taskName = action.payload.task_name;
            state.toeflData.tpoNumber = action.payload.tpo_number;
            state.toeflData.readingText = action.payload.reading;
            state.toeflData.listeningText = action.payload.listening;
            state.toeflData.conversations = action.payload.conversation_data;
            state.toeflData.question = action.payload.question;
            state.toeflData.listeningAudioPath = action.payload.listeningAudioFilePath;
        },
        loadIpb: (state, action) => {
            state.toeflData.question = action.payload.part2;
            state.toeflData.answerOutline = action.payload.part3;
        },
        updateTaskName: (state, action) => {
            state.toeflData.taskName = action.payload;
            if (state.toeflData.tabIndex != 0) {
                state.toeflData.readingText = "";
                state.toeflData.listeningText = "";
                state.toeflData.conversations = "";
                state.toeflData.question = "";
                state.toeflData.tpoNumber = -1;
                state.toeflData.listeningAudioPath = "";
                state.toeflData.answer = "";
            }
            state.markdownResponse = {};
            state.toeflData.shouldShowMarkdown = false;
            state.toeflData.stream_short_id = null;
            state.toeflData.isStreaming = false;
            state.toeflData.audioFile = "";
            state.toeflData.sectionUpdatedTimestamp = Math.floor(Date.now());
        },
        updateCompletionStatus: (state, action) => {
            state.toeflData.completionStatus = action.payload;
        },
        updateReadingText: (state, action) => {
            state.toeflData.readingText = action.payload;
        },
        updateListeningText: (state, action) => {
            state.toeflData.listeningText = action.payload;
        },
        updateQuestion: (state, action) => {
            state.toeflData.question = action.payload;
            state.toeflData.tpoNumber = -1;
        },
        updateConversations: (state, action) => {
            state.toeflData.conversations = action.payload;
        },
        updateTargetScore: (state, action) => {
            state.toeflData.targetScore = action.payload;
        },
        updateAnswerOutline: (state, action) => {
            state.toeflData.answerOutline = action.payload;
        },
        updateAnswer: (state, action) => {
            state.toeflData.answer = action.payload
            state.toeflData.selectedExampleLevel = "";
        },
        updateAudioFile: (state, action) => {
            state.toeflData.audioFile = action.payload;
            state.toeflData.selectedExampleLevel = "";
        },
        updateAudioSource: (state, action) => {
            state.toeflData.audioSource = action.payload;
        },
        updateIsStreaming: (state, action) => {
            if (state.toeflData.isStreaming && !action.payload) { // If just finished streaming
                state.toeflData.shouldShowMarkdown = true;
                if (state.toeflData.section === "writing") {
                    state.toeflData.sectionUpdatedTimestamp = Math.floor(Date.now());
                }
            }
            state.toeflData.isStreaming = action.payload;
        },
        updateRecordingSeconds: (state, action) => {
            state.toeflData.recordingSeconds = action.payload;
        },
        incrementRecordingSeconds: (state, action) => {
            state.toeflData.recordingSeconds += 1;
        },
        updateIntervalId: (state, action) => {
            state.toeflData.intervalId = action.payload;
        },
        updateSelectedExampleLevel: (state, action) => {
            state.toeflData.selectedExampleLevel = action.payload;
            const key = `${state.toeflData.section}_${state.toeflData.taskName}_${state.toeflData.selectedExampleLevel}`;
            if (key in state.toeflData.exampleAnswerMap) {
                const data = state.toeflData.exampleAnswerMap[key];
                if (state.toeflData.section == 'writing') {
                    state.toeflData.answer = data.answer;
                } else {
                    state.toeflData.audioFile = data.audio;
                }
            } else {
                state.toeflData.answer = "";
                state.toeflData.audioFile = "";
            }
        },
        loadQidQuestionData: (state, action) => {
            console.log(action.payload, "state data");
            state.toeflData.section = action.payload.data.section;
            state.toeflData.taskName = action.payload.data.task_name;
            state.toeflData.tpoNumber = action.payload.data.tpo_number;
            state.toeflData.readingText = action.payload.data.reading;
            state.toeflData.listeningText = action.payload.data.listening;
            state.toeflData.question = action.payload.data.question;
            state.toeflData.listeningAudio = action.payload.data.listening_audio;
            state.toeflData.tpoNumber = action.payload.data.tpo_number;

            state.toeflData.bundleName = action.payload.data.bundle_name;
            state.toeflData.genType = action.payload.data.gen_type;

            if (action.payload.data.section === 'writing' && action.payload.data.task_name === 'task3') {
                state.toeflData.conversations = JSON.stringify({
                    "0": [action.payload.data.discussion1_name, action.payload.data.discussion1_content],
                    "1": [action.payload.data.discussion2_name, action.payload.data.discussion2_content],
                    "2": [action.payload.data.discussion3_name, action.payload.data.discussion3_content]
                })
            }

            state.toeflData.answer = action.payload.answer
            if (action.payload.data.audio) {
                state.toeflData.audioFile = action.payload.data.audio
                state.toeflData.audioSource = 'RELOAD'
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            cmApi.endpoints.generateAnswer.matchFulfilled,
            (state, { payload }) => {
                state.markdownResponse = payload;
            }
        ).addMatcher(
            cmApi.endpoints.getExampleAnswer.matchFulfilled,
            (state, { payload }) => {
                state.toeflData.exampleAnswerMap[`${payload.section}_${payload.task}_${payload.level}`] = payload.data;
                if (payload.section == 'writing') {
                    state.toeflData.answer = payload.data.answer;
                } else {
                    state.toeflData.audioFile = payload.data.audio;
                }
            }
        ).addMatcher(
            cmApi.endpoints.getExampleAudio.matchFulfilled,
            (state, { payload }) => {
                state.toeflData.sectionUpdatedTimestamp = Math.floor(Date.now());
            }
        )

    },
});

export const {
    updateContent,
    updateRightSideContent,
    abortGeneration,
    appendResponseTrunk,
    clearResponse,
    clearAnswer,
    clearQuestionAndAnswer,
    handleErrorResponse,
    updateTabIndex,
    updateTabIndexNoReset,
    updateSection,
    updateSelectedProduct,
    changeSection,
    loadHistoryTask,
    loadTpo,
    loadIpb,
    updateTaskName,
    updateCompletionStatus,
    updateReadingText,
    updateListeningText,
    updateConversations,
    updateQuestion,
    updateTargetScore,
    updateAnswerOutline,
    updateAnswer,
    updateAudioFile,
    updateSelection,
    updateAudioSource,
    updateIsStreaming,
    updateRecordingSeconds,
    incrementRecordingSeconds,
    updateIntervalId,
    updateTpoNumber,
    updateToelfGid,
    updateSelectedExampleLevel,
    loadQidQuestionData
} = toeflSlice.actions;

export const selectToeflData = (state) => state.toefl.toeflData;
export const selectRightSideContent = (state) => state.toefl.rightSideContent;
export const selectMarkdownResponse = (state) => state.toefl.markdownResponse;
export default toeflSlice.reducer;
