// Chakra Icons
import {BellIcon} from "@chakra-ui/icons";
// Chakra Imports
import {
    Box, Button, Center,
    Flex, Image, Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Stack, Text, useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
// Custom Icons
import {
    SettingsIcon
} from "components/Icons/Icons";
// Custom Components
import {ItemContent} from "components/Menu/ItemContent";
import {SidebarResponsive} from "components/Sidebar/Sidebar";
import React from "react";
import {NavLink} from "react-router-dom";
import routes from "routes.js";
import Figure from "react-bootstrap/Figure";
import logoPng from "../../assets/logo_long.png";
import {DarkMode} from "@chakra-ui/system";
import {useGetBalanceQuery} from "../../services/cmApi";
import {useTranslation} from "react-i18next";

export default function HeaderLinks(props) {
    const {
        variant,
        children,
        secondary,
        onOpen,
        ...rest
    } = props;

    const {colorMode} = useColorMode();

    let menuBg = useColorModeValue("white", "navy.800");
    const { t, i18n } = useTranslation();
    let balance = useGetBalanceQuery({});
    const getBalanceString = () => {
        if (balance.status !== 'fulfilled') {
            return null;
        }
        return balance.data.balance;
    }

    return (
        <Flex flexDirection="column">
            <Flex
                pe={{sm: "0px", md: "16px"}}
                w={{sm: "100%", md: "auto"}}
                alignItems='center'
                flexDirection='row'>
                <Box flex="1"></Box>
                {/*<SearchBar me='18px' />*/}
                {/*<NavLink to='/auth/signin'>*/}
                {/*  <Button*/}
                {/*    ms='0px'*/}
                {/*    px='0px'*/}
                {/*    me={{ sm: "2px", md: "16px" }}*/}
                {/*    color={navbarIcon}*/}
                {/*    variant='no-effects'*/}
                {/*    rightIcon={*/}
                {/*      document.documentElement.dir ? (*/}
                {/*        ""*/}
                {/*      ) : (*/}
                {/*        <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />*/}
                {/*      )*/}
                {/*    }*/}
                {/*    leftIcon={*/}
                {/*      document.documentElement.dir ? (*/}
                {/*        <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />*/}
                {/*      ) : (*/}
                {/*        ""*/}
                {/*      )*/}
                {/*    }>*/}
                {/*    <Text display={{ sm: "none", md: "flex" }}>Sign In</Text>*/}
                {/*  </Button>*/}
                {/*</NavLink>*/}



                <Menu  defaultIsOpen={true}>
                    <MenuButton>
                        <BellIcon color={props.navbarIconColor} w='20px' h='20px' />
                    </MenuButton>
                    <MenuList p='16px 8px' bg={menuBg}>
                        <Flex flexDirection='column'>
                            <MenuItem borderRadius='8px' mb='10px'>
                                <ItemContent
                                    time='13 minutes ago'
                                    info='from Alicia'
                                    boldInfo='New Message'
                                    aName='Alicia'
                                />
                            </MenuItem>
                        </Flex>
                    </MenuList>
                </Menu>
                <SettingsIcon
                    cursor='pointer'
                    ms={{base: "16px", xl: "16px"}}
                    me='16px'
                    onClick={props.onOpen}
                    color={props.navbarIconColor}
                    w='20px'
                    h='20px'
                />
                <DarkMode>
                    <SidebarResponsive
                        hamburgerColor={props.navbarIconColor}
                        logo={
                            <Stack direction='row' spacing='12px' align='center' justify='center'>
                                {/*{colorMode === "dark" ? (*/}
                                {/*  <ArgonLogoLight w='74px' h='27px' />*/}
                                {/*) : (*/}
                                {/*  <ArgonLogoDark w='74px' h='27px' />*/}
                                {/*)}*/}
                                {/*<Box*/}
                                {/*  w='1px'*/}
                                {/*  h='20px'*/}
                                {/*  bg={colorMode === "dark" ? "white" : "gray.700"}*/}
                                {/*/>*/}
                                {/*{colorMode === "dark" ? (*/}
                                {/*  <ChakraLogoLight w='82px' h='21px' />*/}
                                {/*) : (*/}
                                {/*  <ChakraLogoDark w='82px' h='21px' />*/}
                                {/*)}*/}
                                <Link href='/'>
                                    <Box>
                                        <Figure.Image className="circlex-logo" width={200} height={50} src={logoPng}
                                                      style={{marginBottom: "0px"}}/>
                                    </Box>
                                </Link>
                            </Stack>
                        }
                        colorMode={colorMode}
                        secondary={props.secondary}
                        routes={routes}
                        isOpenRegisterAlert={props.isOpenRegisterAlert}
                        onCloseRegisterAlert={props.onCloseRegisterAlert}
                        isSideBarOpen={props.isSideBarOpen}
                        onSideBarOpen={props.onSideBarOpen}
                        onSideBarClose={props.onSideBarClose}
                        hamburgerButtonRef={props.hamburgerButtonRef}
                        {...rest}
                    />
                </DarkMode>
            </Flex>
            <Link href="/purchase"><Text color={props.navbarIconColor} pt={{base: "0", md: "5px"}} mr="15px">
                <Flex>
                    <Center>
                        <Image src={"/coin_icon.png"} boxSize="15px"/>
                    </Center>
                    <Center ml="10px">
                        <Text>
                            {getBalanceString()}
                        </Text>
                    </Center>
                </Flex>
                </Text></Link>
        </Flex>
    );
}