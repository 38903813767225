import React, { useState } from "react";
import {Box, Button, Center, DrawerBody, DrawerCloseButton, DrawerHeader, DrawerOverlay} from "@chakra-ui/react";
import {useGetTaskHistoryQuery, useGetUserPreferencesQuery} from "../services/cmApi";
import {PaginatedTable} from "./PaginatedTable";
import {ViewResultPanel} from "./ViewResultPanel";

import {
    Flex,
    VStack,
    Drawer,
    DrawerContent,
    useDisclosure,
} from '@chakra-ui/react'
import Figure from "react-bootstrap/Figure";
import logoPng from "../assets/logo_long.png";
import AuthService from "../services/auth.service";
import {HistoryRow} from "./Tables/HistoryRow";
import {getCurrentProduct} from "./utils/common";
import {useSelector} from "react-redux";
import {selectUserPreferences} from "../redux/generalSlice";
import TaskFilterRow from "./TaskFilterRow";
import {useTranslation} from 'react-i18next';

export function HistoryRightPanel(props) {
    let taskHistory = useGetTaskHistoryQuery({}, {skip:!AuthService.getCurrentUser()});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {t, i18n} = useTranslation();

    const getHistoryList = (task_name) => {
        if (taskHistory.status !== 'fulfilled') {
            return [];
        } else {
            if (task_name === 'ALL') {
                return taskHistory.data.data;
            } else {
                return taskHistory.data.data.filter(item => item.gen_type === task_name);
            }
        }
    };

    useGetUserPreferencesQuery({});
    const userPreferences = useSelector(selectUserPreferences);
    
    const [task_name,setTaskName] = useState("ALL");

    let task_options, task_names;
    if (getCurrentProduct(userPreferences) === 'toefl') {
        task_options = [
            'ALL',
            'speaking_task1', 'speaking_task2', 'speaking_task3', 'speaking_task4',
            'speaking_generation',
            'writing_task1', 'writing_task2', 'writing_task3',
            'writing_generation'
        ];
        task_names = [
            t('all'),
            t('speaking_task1'), t('speaking_task2'), t('speaking_task3'), t('speaking_task4'),
            t('speaking_generation'),
            t('writing_task1'), t('writing_task2'), t('writing_task3'),
            t('writing_generation')
        ];
    } else {
        task_options = [
            'ALL',
            'ispeaking_part1', 'ispeaking_part2_3',
            'iwriting_task2'
        ];
        task_names = [
            t('all'),
            t('ispeaking_part1'), t('ispeaking_part2_3'),
            t('iwriting_task2')
        ];

    }

    return <>
        <Flex mx="40px" flexDirection="column" gap="10px">
            <TaskFilterRow
                rowName={t('task')}
                options={task_options}
                names={task_names}
                selectedOption={task_name}
                onChange={(value) => {
                    setTaskName(value);
                }}
            ></TaskFilterRow>
        </Flex>
        <VStack spacing='20px' {...props}>
            <PaginatedTable data={getHistoryList(task_name)} filterProduct={getCurrentProduct(userPreferences)} onRowSelected={onOpen} rowComponent={HistoryRow}></PaginatedTable>
            <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="left">
                <DrawerOverlay />
                <DrawerContent bgColor="gray.100">
                    <DrawerCloseButton mr="20px" mt="20px" color="white" />
                    <DrawerHeader bg="blue.800">
                        <Box bg="blue.800">
                            <Figure.Image width={200} height={20} src={logoPng} style={{ marginBottom: "0px" }} />
                        </Box>
                    </DrawerHeader>
                    <DrawerBody bg="white" px={0}>
                        <ViewResultPanel></ViewResultPanel>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </VStack>
    </>
}