import React, {useEffect, useState} from "react";
// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Grid,
    Heading,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import {useTranslation} from "react-i18next";
import AuthService from "services/auth.service";
import {useGetPromotionCodeQuery} from "services/cmApi";
import authHeader from "services/auth-header";
import axios from "axios";
import {QuickLinksPanel} from "components/QuickLinksPanel";
import campus_background from "assets/img/campus_background.jpg";
import {RegisterAlertModal} from "components/modals/RegisterAlertModal";
import {useHistory} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

function PromotionPanel1(props) {
    const [linkCopied, setLinkCopied] = useState(false);
    const {t, i18n} = useTranslation();
    return (
        <Flex m="5px" flexDirection="column">
            <Text fontSize='sm' whiteSpace="pre-line">
                {props.shareRules}
            </Text>
            <Flex flexDirection="column" flex="1" bgColor="#FFECD1" px="10px">
                <Text pt='2' fontSize='sm' my="10px" p="5px" whiteSpace="pre-line">
                    {props.shareMessage}
                </Text>
                <Box flex="1"></Box>
                <Flex flexDirection="row" mb="10px">
                    <Box flex="1"></Box>
                    <Button
                        mx="10px"
                        colorScheme="yellow"
                        bgColor="#F96730"
                        color="white"
                        display={props.copiable ? "block" : "none"}
                        onClick={() => {
                            navigator.clipboard.writeText(props.shareMessage);
                            setLinkCopied(true);
                            setTimeout(() => {
                                setLinkCopied(false);
                            }, "2000");
                        }}
                    >
                        {linkCopied ? t("already_copied") : t("copy")}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}


function CampusCertificationGuidance() {
    const isMobile = useBreakpointValue({base: true, md: false});
    const {t, i18n} = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    let currentUser = AuthService.getCurrentUser();
    const {isOpen: isOpenRegisterAlert, onOpen: onOpenRegisterAlert, onClose: onCloseRegisterAlert} = useDisclosure();
    const [shareLink, setShareLink] = useState('');
    const [promotionType, setPromotionType] = useState("CampusCertify")
    let promotion_info = useGetPromotionCodeQuery({promotion_type: promotionType});
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const [campusCertifyStatus, setCampusCertifyStatus] = useState(currentUser ? currentUser.campus_certify_status : '');
    let history = useHistory();

    useEffect(() => {
        AuthService.getMyInfo().then((data) => {
                currentUser = data
                setCampusCertifyStatus(data.campus_certify_status)
            },
        )
        if (!currentUser) {
            return;
        }
        let headers = authHeader();
        axios.get(API_URL + '/api/get_referral', {headers: headers}).then((response) => {
            setShareLink(`${API_URL}/register?r=${response.data}&is_campus`);
        })
    }, []);  // <-- empty dependency array to run only once on mount

    useEffect(() => {
        if (campusCertifyStatus === "CERTIFIED") {
            promotion_info.refetch();
        }
    }, [campusCertifyStatus]);


    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{sm: "1fr", lg: "4fr 1fr"}}
                templateRows={{lg: "repeat(2, auto)"}}
                gap='20px'>
                <Card overflowX={{sm: "scroll", xl: "hidden"}} py="22px" px={{base: '4', md: '10'}}>
                    <Center>
                        <Heading size={isMobile ? '2xl' : '4xl'}>「学 生 价 优 惠」</Heading>
                    </Center>
                    <Box m="8px"></Box>
                    <Center>
                        <Heading size={isMobile ? 'md' : 'lg'}>8.5 折学生价、免费月卡等你来！</Heading>
                    </Center>
                    <Box m="15px"></Box>
                    <RegisterAlertModal isOpen={isOpenRegisterAlert}
                                        onClose={onCloseRegisterAlert}></RegisterAlertModal>
                    <Center>
                        <Box maxW='1000px' width="100%" borderRadius='20px' bg="gray.100" py={{base: '4', md: '10'}}
                             px={{base: '4', md: '10'}}>
                            {campusCertifyStatus === "CERTIFIED" ?
                                <Flex flexDirection="column">
                                    <Heading size={'md'}>活动 1：完成学校认证，即可获得 8.5
                                        折学生价(「已认证学生身份」{currentUser.campus_name}学校)</Heading>
                                    <Box m="8px"></Box>
                                    <Center>
                                        {promotion_info.status === 'fulfilled' && promotion_info.data.promotion_code ?
                                            <Flex flexDirection="row">
                                                <Text size={'md'} fontWeight={"bold"}>优惠码：</Text>
                                                <Text size={'md'}
                                                      color={'green'}>{promotion_info.data.promotion_code}</Text>
                                                <Text size={'sm'}>（仅可使用一次）</Text>
                                                <Link color={"red.400"} style={{textDecoration: "underline"}}
                                                      target="_blank"
                                                      href={"https://lingoleap.ai/zh/read/%e6%8a%98%e6%89%a3%e7%a0%81%e4%bd%bf%e7%94%a8%e8%af%b4%e6%98%8e/"
                                                      }>优惠码使用说明</Link>
                                            </Flex> :
                                            <Button
                                                width="200px"
                                                onClick={() => {
                                                    window.location.reload()
                                                }} colorScheme='yellow' borderRadius='20px'
                                            >获取折扣码（8.5折）</Button>
                                        }
                                    </Center>
                                </Flex> :
                                <Flex flexDirection="column">
                                    {campusCertifyStatus === "WAITING" ?
                                        <Flex flexDirection="column">
                                            <Heading size={'md'}>活动 1：完成学校认证，即可获得 8.5
                                                折学生价（正在等待人工审核）</Heading>
                                            <Box m="8px"></Box>
                                            <Center>
                                                <Flex align="center">
                                                    <Button colorScheme='yellow' borderRadius='20px'
                                                            onClick={() => {
                                                                AuthService.getMyInfo().then((data) => {
                                                                    setCampusCertifyStatus(data.campus_certify_status)
                                                                })
                                                            }}>刷新</Button>
                                                    <Box m="8px"></Box>
                                                    <Link color="blue.500" onClick={() => {
                                                        history.push("/campus_certification");
                                                    }}>
                                                        立即认证
                                                    </Link>
                                                </Flex>
                                            </Center>
                                        </Flex> :
                                        <Flex flexDirection="column">
                                            <Heading size={'md'}>活动 1：完成学校认证，即可获得 8.5 折学生价</Heading>
                                            <Box m="8px"></Box>
                                            <Center>
                                                <Button
                                                    onClick={event => {
                                                        currentUser ?
                                                            window.location.href = "/campus_certification" :
                                                            onOpenRegisterAlert()
                                                    }
                                                    } colorScheme='yellow' borderRadius='20px'
                                                >立即认证</Button>
                                            </Center>
                                        </Flex>}
                                </Flex>
                            }
                            <Box m="15px"></Box>
                            <Heading size={'md'}>活动 2：校园论坛分享·领1000积分</Heading>
                            <Box m="8px"></Box>
                            <Center width="100%">
                                <Button
                                    onClick={() => {
                                        currentUser ?
                                            onOpen() :
                                            onOpenRegisterAlert()
                                        ;
                                    }} colorScheme='yellow' borderRadius='20px'
                                >生成专属连接</Button>
                            </Center>
                        </Box>
                    </Center>
                    <Box m="15px"></Box>
                    <Center>
                        <Image src={campus_background} maxW='1000px' width="100%" objectFit="cover"/>
                    </Center>
                    <Modal isOpen={isOpen} onClose={onClose} width="800px">
                        <ModalOverlay/>
                        <ModalContent maxWidth="66rem" bgColor="#F9F6E7" borderRadius="3xl">
                            <ModalHeader>{t("backtoschool_title0")}</ModalHeader>
                            <ModalCloseButton/>
                            <ModalBody>
                                <Flex flexDirection="column">
                                    <PromotionPanel1
                                        shareRules={t("share_rules_campus")}
                                        shareMessage={t("share_message_campus") + " " + shareLink}
                                        copiable={true}
                                    ></PromotionPanel1>
                                    <Flex flexDirection={isMobile ? "column" : "row"} m="10px">
                                        <Text>{t("backtoschool_footnote")}</Text>
                                        <Box flex="1"></Box>
                                        <Link color="blue.500" onClick={() => {
                                            history.push("/account");
                                        }}>查看邀请进度>></Link>
                                    </Flex>
                                </Flex>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </Card>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
        </Flex>
    );
};

export default CampusCertificationGuidance;