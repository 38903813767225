import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from "../services/auth-header";
import { Table, Thead, Tbody, Tr, Th, Td, Button, Fade, Center } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box,
    VStack,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody
} from '@chakra-ui/react'
import FeedBackImage from './FeedBackImage';
import { ViewResultPanel } from './ViewResultPanel';

export function ViewFeedBackPanel(users) {
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const [feedBacks, setFeedBacks] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();
    const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
    const [imgName, setImgName] = useState(null);
    const [detail, setDetail] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [gid, setGid] = useState('')
    // const users = props.users //all users
    const [userMap, setUserMap] = useState({})
    const fetchFeedbacks = async () => {
        try {
            let headers = authHeader();
            const response = await axios.get(API_URL + `/api/get_feedbacks`, { headers: headers });
            const sortedFeedbacks = response.data.sort((a, b) => {
                const dateA = new Date(a.create_at);
                const dateB = new Date(b.create_at);
                return dateB - dateA;
              });
            setFeedBacks(sortedFeedbacks);
        } catch (err) {
            console.error(err);
        }
    }
    
    useEffect(() => {
        fetchFeedbacks();
        if (users) {
            mapUsers();
        }        
    }, [users]);


    const mapUsers = () => {
        let newUserMap = {};
        users.forEach(function (user) {
            let userInfo = { 'email': user.email, 'username': user.username, 'uid': user.uid }
            newUserMap[user.id] = userInfo;
        });
        setUserMap(newUserMap);
        console.log(newUserMap, 'users')
    }



    const imageModal = () => {
        return (<Modal isOpen={isImageOpen} onClose={onImageClose} size={'auto'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FeedBackImage imgName={imgName} />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onImageClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
    }
    const showFeedBacks = () => {
        return (
            <Box m={10}>
                <Table size={'md'} variant='striped' colorScheme='teal'>
                    <Thead>
                        <Tr>
                            <Th>User ID</Th>
                            <Th>User email</Th>
                            <Th>Gpt response</Th>
                            <Th>Details</Th>
                            <Th>Rate</Th>
                            <Th>Photo</Th>
                            <Th>Contact Info</Th>
                            <Th>Created At</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {feedBacks.map(feedback => (
                            <Tr key={feedback.user_id}>
                                <Td>{feedback.user_id}</Td>
                                <Td>{userMap && userMap[feedback.user_id] ? userMap[feedback.user_id].email : "loading"}</Td>
                                <Td>
                                    {feedback.gid ?
                                        <Button onClick={() => { setGid(feedback.gid); onOpen(); }}>View Gpt response</Button> : <>No details</>}
                                </Td>
                                {/* <Td>{feedback.details['content']}</Td> */}
                                <Td>
                                    {feedback.details['content'] ?
                                        <Button onClick={() => showDetail(feedback.details['content'])}>View Details</Button> : <>No details</>}
                                </Td>

                                <Td >{feedback.rate}</Td>
                                <Td>  {feedback.photo_file_name ? <>
                                    <Button onClick={() => { setImgName(feedback.photo_file_name); onImageOpen(); }}>View Photo</Button></> :
                                    <>No photo</>}
                                </Td>
                                <Td>{feedback.contact_info}</Td>
                                <Td>{feedback.create_at}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        );

    }
    const detailModal = () => {
        return (<Modal isOpen={isDetailOpen} onClose={onDetailClose} size={'md'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Detail</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {detail}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onDetailClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
    }
    const showDetail = (content) => {
        setDetail(content);
        onDetailOpen();
    }

    console.log(imgName)

    return (
        <>
            <VStack spacing='20px'>
                <Button onClick={() => setShowTable(!showTable)}>Show Feedbacks</Button>
                <Box display={showTable ? "block" : "none"}>
                    {showFeedBacks()}
                </Box>
                <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="bottom">
                    <DrawerOverlay />
                    <DrawerContent bgColor="gray.100">
                        <DrawerCloseButton m={'50px'} color="black" />
                        <DrawerHeader bg="blue.800">
                        </DrawerHeader>
                        <DrawerBody bg="white">
                            <ViewResultPanel gid={gid}></ViewResultPanel>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
                {imageModal()}
                {detailModal()}
            </VStack>

        </>
    )
}
// {feedback.photo_file_name}