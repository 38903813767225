import React, { useEffect, useState } from "react";
import {
    Box,
    Button, Center,
    Checkbox,
    Container,
    Divider, Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input, Link,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
//import { Logo } from './Logo'
//import { OAuthButtonGroup } from './OAuthButtonGroup'
import { PasswordField } from './components/PasswordField'
import { useLoginMutation } from "./services/cmApi";
import AuthService from "./services/auth.service";
import axios from "axios";
import { CheckIcon } from "@chakra-ui/icons";
import Figure from "react-bootstrap/Figure";
import logoPng from "./assets/logo_long.png";
import { selectToeflData } from "redux/toeflSlice";
import { OAuthButtonGroup } from "./components/OAuthButtonGroup";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;


export const LoginSuccess = (props) => {
    const toeflData = useSelector(selectToeflData);
    const currentUser = AuthService.getCurrentUser();
    let history = useHistory();

    if (currentUser) {
        history.push('/toefl/speaking')
        return null;
    }

    console.log(toeflData.selectedProduct, "toeflProduct")

    const { t, i18n } = useTranslation();
    console.log("i18n language", i18n.language)
    const toast = useToast();


    useEffect(() => {
        //AuthService.getMyInfo();

        AuthService.getMyInfo().then(
            () => {
                const user = JSON.parse(localStorage.getItem('user'))
                mixpanel.identify(user.uid);
                mixpanel.people.set({
                    $name: user.user_name,
                    source: "social_login",
                });
                const { from } = props.location.state || { from: { pathname: `/${toeflData.selectedProduct ? toeflData.selectedProduct : "toefl"}/speaking` } };
                history.push(from);
            },
            (error) => {
                console.log(error);
                toast({
                    // title: t("login_error"),
                    description: error.response.status != 401 ? error.message : error.response.data.error,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                //console.log(error.message)
                if (error.response.data.code == "error_login_user_not_verified") {
                    //console.log("setPendingVerification")
                    setPendingVerification(true);
                }
                console.log("login error")
                console.log(error);
            }
        );
    }, []);  // <-- empty dependency array to run only once on mount

    return (
        <></>
    )
}

export default LoginSuccess;
