import {Box, Center, Flex, Tab, TabList, Tabs, Text, Wrap, WrapItem} from "@chakra-ui/react";
import React from "react";

export default function TaskFilterRow(props) {
    var {rowName, options, names, selectedOption, onChange, bundleName, ...other} = props;

    return (
        <Flex>
            <Center>
                <Text whiteSpace="nowrap" fontWeight={600} fontSize='sm' color={'gray.700'}
                      paddingLeft={3}>{rowName}
                </Text>
            </Center>
            <Box mx="20px"></Box>
            <Center>
                <Tabs
                    variant='unstyled'
                    colorScheme='blue'
                    index={options.findIndex((x) => x === selectedOption)}
                    onChange={(index) => {
                        onChange(options[index]);
                    }}
                >
                    
                        <TabList gap={3}>
                        <Wrap spacing={"2"} justify={"center"}>
                            {names.map((value) => {
                                return (
                                    <WrapItem>
                                        <Tab
                                            _selected={{ color: 'white', bg: 'green.500' }}
                                            fontSize='sm'
                                            borderRadius='full'
                                            borderWidth='1px'
                                            borderColor='gray.200'
                                            px='10px'
                                            py='3px'
                                        >{value}</Tab>
                                    </WrapItem>
                                )
                            })}
                            </Wrap>
                        </TabList>
                    
                </Tabs>
            </Center>
        </Flex>
    )
}