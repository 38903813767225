import React from 'react';
import {
    Button,
    Center,
    Flex,
    Text,
    Box,
    CircularProgress,
    CircularProgressLabel, Grid, SimpleGrid,
} from '@chakra-ui/react';
import Card from './Card/Card';
import CardBody from './Card/CardBody';
import {selectToeflData} from 'redux/toeflSlice';
import {useSelector} from 'react-redux';
import {ToeflRightPanel} from "./ToeflRightPanel";
import {QuickLinksPanel} from "./QuickLinksPanel";


function SubScoreCard(props) {
    var {title, subscore, description, color, ...other} = props;

    return (
        <Flex flexDirection="column">
            <Card border="2px" borderColor={color}>
                <Flex direction="row" justify="space-between" alignItems="center">
                    <Text fontSize="18px" fontWeight="bold">
                        {title}
                    </Text>
                    <CircularProgress value={60} color={color} size="80px">
                        <CircularProgressLabel
                            color={color}
                            fontWeight="bold"
                            fontSize="3xl"
                        >
                            {subscore}
                        </CircularProgressLabel>
                    </CircularProgress>
                </Flex>
                <Box maxW="full" maxH="200px" overflowY="scroll" mt="20px">
                    <Text>{description}</Text>
                </Box>
            </Card>
        </Flex>

    )
}


export default function EssayScore() {
    const toeflData = useSelector(selectToeflData);
    const message1 = toeflData.content.message1
        ? JSON.parse(toeflData.content.message1)
        : toeflData.content.message1;


    return (
        <Box maxW="full" padding="3rem">
            {/*<Flex direction="column" gap="10px" textColor="white">*/}
            {/*    <Button bgColor="blue.400">Check Essay Now</Button>*/}
            {/*    <Button bgColor="gray.400">Clear and enter my essay</Button>*/}
            {/*    <Button bgColor="green.400">Start Timer</Button>*/}
            {/*</Flex>*/}

            <Center>
                <Flex
                    direction="column"
                    // border="2px"
                    // borderColor='blue.500'
                    borderRadius='30px'
                >
                    <Flex direction="column">
                        <Center mt="10px">
                            <Text textColor="gray">Overall Brand Score</Text>
                        </Center>
                        <Center>
                            <Text textColor="white" fontSize="5rem" fontWeight="bold" color="skyblue" px='30px'>
                                {(
                                    (message1?.task_fulfillment +
                                        message1?.organization +
                                        message1?.coherence_cohesion +
                                        message1?.language_use) /
                                    4
                                ).toFixed(2)}
                            </Text>
                        </Center>

                    </Flex>
                    {/*<Button textColor="white" bgColor="green.400">*/}
                    {/*    IELTS AI 3.0*/}
                    {/*</Button>*/}
                    <Text m="10px" maxW="500px">
                        Your essay was good overall, showing a good understanding of the
                        structure and coherence of text. You covered all aspects of the
                        task, providing clear arguments and examples. There were only a few
                        minor issues related to grammar and vocabulary usage, but these did
                        not significantly affect the clarity of your message.
                    </Text>
                </Flex>
            </Center>

            <Flex
                direction="column"
                rowGap="12px"
                justify="center"
                marginTop="20px"
                maxW="full"
            >
                <SimpleGrid columns={4} spacing={10}>
                    <SubScoreCard
                        title='Task Response'
                        subscore={message1?.task_fulfillment}
                        description={message1?.task_fulfillment_description}
                        color='blue.300'
                    ></SubScoreCard>
                    <SubScoreCard
                        title='Coherence and Cohesion'
                        subscore={message1?.coherence_cohesion}
                        description="Your essay is logically structured and ideas progression is clear, but
                                there are a few areas where cohesion could be improved. Statement like
                                'First and foremost' could have been avoided or replaced with more
                                nuanced transition phrases."
                        color='black'
                    ></SubScoreCard>
                    <SubScoreCard
                        title='Organization Description'
                        subscore={message1?.organization}
                        description={message1?.organization_description}
                        color='green.400'
                    ></SubScoreCard>
                    <SubScoreCard
                        title='Language Use'
                        subscore={message1?.language_use}
                        description={"Your usage of complex sentence forms is good, but there are some\n" +
                            "                                punctuation errors and grammatical errors in your work. For instance,\n" +
                            "                                the phrase 'Not only did a person have momentum on the way of solving a\n" +
                            "                                daily basic work but, he also is able to save time to learn new things\n" +
                            "                                again' could be correctly rephrased as 'Not only does a person gain\n" +
                            "                                momentum in solving daily tasks, but they also save time by not having\n" +
                            "                                to learn new things.'"}
                        color='yellow.400'
                    ></SubScoreCard>
                </SimpleGrid>
            </Flex>
        </Box>
    );
}
