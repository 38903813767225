import React, {useEffect} from "react";
import {selectToeflData, updateSection, updateTabIndex} from "../redux/toeflSlice";
import {FiBookmark, FiBookOpen} from "react-icons/fi";
import {FaPen} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import {mapToeflTabIndex} from "./utils/common";
import QuestionListPanel from "./QuestionListPanel";
import ToeflCustomQuestionPanel from "./ToeflCustomQuestionPanel";
import {RightPanel} from "./RightPanel";

export function ToeflRightPanel(props) {
    const toeflData = useSelector(selectToeflData);
    const tabIndex = toeflData.tabIndex;
    const {t, i18n} = useTranslation();
    var tabList = [
        {
            "icon": FiBookOpen,
            "display": true,
            "title": "TPO",
            "showName": "TPO",
            "component": <QuestionListPanel test='toefl' section={toeflData.section} questionSet={"tpo"}></QuestionListPanel>
        },
        {
            "icon": FiBookmark,
            "display": true,
            "title": "QuestionBank",
            "showName": "question_bank",
            "component": <QuestionListPanel test='toefl' section={toeflData.section} questionSet={"questionBank"}/>
        },
        {
            "icon": FaPen,
            "display": true,
            "title": "CustomQuestion",
            "showName": "customized_topic",
            "component": <ToeflCustomQuestionPanel></ToeflCustomQuestionPanel>
        }]

    const dispatch = useDispatch();
    useEffect(() => {
        if (window.location.pathname.includes('writing') && toeflData.section !== 'writing') {
            dispatch(updateSection('writing'))
        }
    }, [])

    return <>
        <RightPanel tabIndex={tabIndex} updateTabIndexfunc={updateTabIndex} mapTabIndexfunc={mapToeflTabIndex}
                    tabList={tabList}/>
    </>
}