import {
    FormControl,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    useDisclosure,
    useMergeRefs,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next';
import {forwardRef, useRef} from 'react'
import {HiEye, HiEyeOff} from 'react-icons/hi'

export const PasswordField = forwardRef((props, ref) => {
    const {isOpen, onToggle} = useDisclosure()
    const inputRef = useRef(null)
    const mergeRef = useMergeRefs(inputRef, ref)
    const {t, i18n} = useTranslation();
    const onClickReveal = () => {
        onToggle()
        if (inputRef.current) {
            inputRef.current.focus({
                preventScroll: true,
            })
        }
    }
    return (
        <FormControl>
            {/*<FormLabel htmlFor="password">Password</FormLabel>*/}
            <InputGroup>
                <InputRightElement>
                    <IconButton
                        variant="link"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff/> : <HiEye/>}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
                <Input
                    placeholder={t("password")}
                    style={{
                        borderBottom: "1px solid #ccc",
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        display: "block",
                        width: "100%",
                        padding: "8px 0",
                        color: "#999",
                        fontSize: "16px",
                        outline: "none",
                        transition: "border-color 0.3s ease",
                        borderRadius: 0,
                    }}
                    id="password"
                    ref={mergeRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="off"
                    // autoComplete="current-password"
                    required
                    {...props}
                />
            </InputGroup>
        </FormControl>
    )
})
PasswordField.displayName = 'PasswordField'