import React, {useEffect, useState} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {useDispatch, useSelector} from "react-redux";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react'

import {Textarea} from "@chakra-ui/textarea"
import {
    abortGeneration,
    appendResponseTrunk,
    clearResponse,
    handleErrorResponse,
    incrementRecordingSeconds,
    selectMarkdownResponse,
    selectToeflData,
    updateAnswer,
    updateAnswerOutline,
    updateAudioFile,
    updateIntervalId,
    updateIsStreaming,
    updateListeningText,
    updateQuestion,
    updateReadingText,
    updateRecordingSeconds,
    updateTargetScore,
    updateTaskName
} from "../redux/toeflSlice";
import {ResponsePanel} from "./ResponsePanel";
import ReactAudioPlayer from 'react-audio-player';
import AuthService from "../services/auth.service";
import {
    cmApi,
    useGetBalanceQuery,
    useGetUserPreferencesQuery,
    useReportMutation,
    useUpdateUserPreferencesMutation
} from "../services/cmApi";
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import NewUserPopover from "./NewUserPopover";
import {ShareResultButton} from "./ShareResultButton";
import ConversationTable from './ConversationTable';
import TestSelectionModal from "./TestSelectionModal";
import {mapToeflTabIndex} from "./utils/common";
import mixpanel from 'mixpanel-browser';

export default function ToeflCustomQuestionPanel(props) {
    const { t, i18n } = useTranslation();

    const toeflData = useSelector(selectToeflData);
    const markdownResponse = useSelector(selectMarkdownResponse);

    const dispatch = useDispatch();
    const toast = useToast();
    const cancelRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isChargeAlertOpen,
        onOpen: onChargeAlertOpen,
        onClose: onChargeAlertClose
    } = useDisclosure()
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();

    const [silenceChargeAlert, setSilenceChargeAlert] = React.useState(true);
    let answer = toeflData.answer ? toeflData.answer : "";
    let wordCount = answer.trim() === "" ? 0 : answer.trim().split(/\s+/).length;
    const tabIndex = toeflData.tabIndex;
    const section = toeflData.section;
    const isSelectTpo = toeflData.tpoNumber != -1;
    let showReading, showListening;
    const isDiscussion = (toeflData.section == "writing" && toeflData.taskName == "task3")

    let isSpeaking = section == 'speaking';
    let isWriting = section == 'writing';

    let isGrading = tabIndex == 0 || tabIndex == 1;
    let isCustomGrading = mapToeflTabIndex(tabIndex) == 'CustomQuestion';
    let isGeneration = tabIndex == -1;
    let isPractice = tabIndex == -1;

    const [reportRequest, reportRequestError] = useReportMutation();


    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            return;
        }
        const query = new URLSearchParams(window.location.search);
        if (query.get("noreset")) {
            return;
        }
    }, []);

    let showAnswer = (isWriting);
    let showRecordingFunctions = (isSpeaking);

    if (isSpeaking) {
        showReading = (toeflData.taskName !== 'task1' && toeflData.taskName !== 'task4');
        showListening = toeflData.taskName !== 'task1';
    } else if (isWriting) {
        showReading = (toeflData.taskName == 'task1');
        showListening = toeflData.taskName == 'task1';
    } else {
        showReading = false;
        showListening = false;
    }

    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    let preferences = useGetUserPreferencesQuery({});

    let balance = useGetBalanceQuery({});


    const formatRecordingSeconds = (s) => {
        const min = Math.floor(s / 60);
        const sec = s % 60;

        return " " + min + ":" + String(sec).padStart(2, '0');
    };

    const getTaskOptions = () => {
        if (isSpeaking) {
            return <>
                <option value='task1'>{t("speaking_task1")}</option>
                <option value='task2'>{t("speaking_task2")}</option>
                <option value='task3'>{t("speaking_task3")}</option>
                <option value='task4'>{t("speaking_task4")}</option>
            </>
        } else if (isWriting) {
            return <>
                <option value='task1'>{t("writing_task1")}</option>
                <option value='task2'>{t("writing_task2")}</option>
                <option value='task3'>{t("writing_academic")}</option>
            </>
        } else {
            return <></>
        }
    }
    let history = useHistory();
    const handleRouteChange = (props) => {
        let path = `/tpo/${toeflData.section}/${toeflData.taskName}/${toeflData.tpoNumber}`;
        history.push(path);
    }

    const handleUpload = (e) => {
        const reader = new FileReader();
        let file = e.target.files[0]
        reader.readAsDataURL(file);
        if (file.size > 5 * 10 ** 6) {
            alert("Audio file needs to be < 5M. Consider convert the audio to mp3 or other formats.")
        }

        reader.onloadend = async () => {
            dispatch(updateAudioFile(reader.result));
        };
    };

    const getOS = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    };

    const handleRecord = () => {
        if ("MediaRecorder" in window) {
            if (!isRecording) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then((mediaStream) => {
                        const recorder = new MediaRecorder(mediaStream);
                        setMediaRecorder(recorder);

                        recorder.start();

                        recorder.addEventListener('dataavailable', (e) => {
                            const file = new File([e.data], 'recorded_audio.webm', { type: e.data.type });
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = async () => {
                                dispatch(updateAudioFile(reader.result));
                                recorder.stream.getTracks().forEach((t) => t.stop());
                                setMediaRecorder(null);
                            }
                        });

                        setIsRecording(true);
                        dispatch(updateRecordingSeconds(0));
                        const intervalId = setInterval(() => {
                            dispatch(incrementRecordingSeconds());
                        }, 1000);
                        dispatch(updateIntervalId(intervalId));

                    })
                    .catch((r) => {
                        const os = getOS();
                        let msg = t('mic_ask_access');
                        if (os === "iOS") {
                            msg = t('mic_access_direction')
                        }
                        toast({
                            title: t('mic_unable_to_turn_on'),
                            description: msg,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        });
                        console.log("Error in obtaining media stream: ", r.message, os)
                    });
            } else {
                if (mediaRecorder) {
                    mediaRecorder.stop();
                }

                setIsRecording(false);
                clearInterval(toeflData.intervalId);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    const validateEvaluationInput = () => {
        if (isSpeaking) {
            if (!toeflData.audioFile) {
                alert(t("empty_audio_message"));
                return false;
            }
            console.log("passed audio data check")
        } else if (isSpeaking) {
            if (!toeflData.tpoNumber || !toeflData.question) {
                alert(t("empty_question_message"));
                return false;
            }
        } else if (isWriting && isGrading) {
            if (!toeflData.answer) {
                alert(t("empty_answer_message"));
                return false;
            }
        } else if (isWriting) {
            if (!toeflData.tpoNumber || !toeflData.question) {
                alert(t("empty_question_message"));
                return false;
            }
        }
        return true;
    }
    const prepareRequestParams = () => {
        if (isSpeaking) {
            return {
                type: 'speaking_' + toeflData.taskName,
                topic: toeflData.question,
                audio: toeflData.audioFile,
                reading: toeflData.readingText,
                listening: toeflData.listeningText,
                example_level: toeflData.selectedExampleLevel,
                tpo_number: toeflData.tpoNumber
            }
        } else {
            if (isSpeaking) {
                return {
                    type: 'speaking_generation',
                    topic: toeflData.question,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber
                }
            } else if (isWriting) {
                if (toeflData.taskName == "task1") {
                    return {
                        type: 'writing_task1',
                        topic: toeflData.question,
                        content: toeflData.answer,
                        reading: toeflData.readingText,
                        listening: toeflData.listeningText,
                        example_level: toeflData.selectedExampleLevel,
                        tpo_number: toeflData.tpoNumber
                    }
                } else if (toeflData.taskName == "task2") {
                    return {
                        type: 'writing_task2',
                        topic: toeflData.question,
                        content: toeflData.answer,
                        example_level: toeflData.selectedExampleLevel,
                        tpo_number: toeflData.tpoNumber
                    }
                } else if (toeflData.taskName == "task3") {
                    return {
                        type: 'writing_task3',
                        topic: toeflData.question,
                        content: toeflData.answer,
                        reading: toeflData.readingText,
                        conversations: toeflData.conversations,
                        example_level: toeflData.selectedExampleLevel,
                        tpo_number: toeflData.tpoNumber
                    }
                } else {
                    alert("Unknown task name: " + toeflData.taskName);
                }
            } else if (isWriting) {
                return {
                    type: 'writing_generation',
                    topic: toeflData.question,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber
                }
            }
        }
    };

    const getChargeAlertKey = () => {
        let key = "shouldSilence_tab" + toeflData.tabIndex + "_" + section + "_" + toeflData.taskName;

        return key;
    }

    const shouldShowChargeAlert = () => {
        if (toeflData.selectedExampleLevel) {
            return false;
        }

        if (preferences.status !== "fulfilled") {
            return true;
        }

        if (balance <= 200) {
            return true;
        }

        const key = getChargeAlertKey();
        if (key in preferences.data) {
            return !preferences.data[key];
        } else {
            return true;
        }
    };

    const getChargeAmount = () => {
        if (toeflData.section === 'writing') {
            return 200;
        } else if (toeflData.section === 'speaking') {
            return 100;
        }
        return 100;
    }

    const handleSubmit = () => {
        console.log("handleSubmit")
        const params = prepareRequestParams();
        console.log("prepared params", params)
        if (!params) {
            return;
        }

        if (shouldShowChargeAlert() && silenceChargeAlert) {
            let prefs = {};
            prefs[getChargeAlertKey()] = true;
            updateUserPreferencesRequest(prefs)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                })
                .catch(console.error);
        }

        let startTime = Math.floor(Date.now());
        mixpanel.track('Submit Button Click', {
            function_string: toeflData.section + toeflData.tabIndex,
            tpo_int: toeflData.tpoNumber,
            example_level_string: toeflData.selectedExampleLevel,
            task_string: toeflData.taskName,
        });

        dispatch({
            type: `${cmApi.reducerPath}/invalidateTags`,
            payload: ['History'],
        });

        dispatch(updateIsStreaming(true));

        params['authorization'] = AuthService.getCurrentUser()["token"];
        params['lang'] = i18n.language;

        let url = process.env.REACT_APP_WS_BACKEND_URL ? process.env.REACT_APP_WS_BACKEND_URL : window.location.origin;
        url = url.replace("https://", "wss://").replace("http://", "ws://");
        url = url + "/api/generate_ws";
        const ws = new WebSocket(url);

        ws.onopen = (event) => {
            ws.send(JSON.stringify(params));
        };

        ws.onmessage = function (event) {
            let data = JSON.parse(event.data);
            if (data.t === "data") {
                dispatch(appendResponseTrunk(data));
            } else if (data.t === "metadata") {
                dispatch(clearResponse(data.c));
                reportRequest({
                    "type": "start_streaming",
                    "start_time": startTime / 1000,
                    "duration": (Math.floor(Date.now()) - startTime) / 1000,
                    "gid": data.c.gid
                }).then(function (response) { });
            } else if (data.t === "error") {
                dispatch(handleErrorResponse(data));
            }
        };

        ws.onclose = function (event) {
            dispatch(updateIsStreaming(false));
            dispatch({
                type: `${cmApi.reducerPath}/invalidateTags`,
                payload: ['History'],
            });
            reportRequest({
                "type": "finish_streaming",
                "start_time": startTime / 1000,
                "duration": (Math.floor(Date.now()) - startTime) / 1000,
            }).then(function (response) { });
        };

        ws.onerror = function (error) {
            console.log("WebSocket error: ", error);
            reportRequest({
                "type": "error_streaming",
                "start_time": startTime / 1000,
                "duration": (Math.floor(Date.now()) - startTime) / 1000,
                "error": error
            }).then(function (response) { });
        };
    };

    const getTaskPanel = () => {
        return (
            <>
                <Flex mt={"10px"} display={showReading ? "block" : "none"}>
                    <Flex flexDirection={"row"} margin={0} >
                        <Text fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{t('reading_text')}
                        </Text>
                        <Box flex={"1"}></Box>
                    </Flex>
                    <Textarea
                        display={tabIndex != 0 ? "block" : "none"}
                        onChange={(e) => {
                            dispatch(updateReadingText(e.target.value));
                        }} value={toeflData.readingText}
                        height="200px"
                        spellcheck="false"
                    ></Textarea>
                    <Text
                        textAlign={'justify'}
                        whiteSpace='pre-line'
                        color="gray.600"
                        px="20px"
                        display={tabIndex == 0 ? "block" : "none"}
                        fontSize={'md'}>
                        {toeflData.readingText}
                    </Text>
                </Flex>
                <Flex mt={"10px"} display={showListening ? "block" : "none"}>
                    <Flex flexDirection={"row"} margin={0}>
                        <Text fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{t('listening_text')}
                        </Text>
                        <Box flex={"1"}></Box>
                    </Flex>
                    <Textarea
                        display={tabIndex != 0 ? "block" : "none"}
                        onChange={(e) => {
                            dispatch(updateListeningText(e.target.value));
                        }} value={toeflData.listeningText}
                        height="200px"
                        spellcheck="false"
                    ></Textarea>

                    <Text
                        textAlign={'justify'}
                        whiteSpace='pre-line'
                        color="gray.600"
                        px="20px"
                        display={tabIndex == 0 ? "block" : "none"}
                        fontSize={'md'}>
                        {toeflData.listeningText}
                    </Text>
                </Flex>
                <Flex flexDirection={"row"} margin={0}>
                    <Text mt={"10px"} fontWeight={600} fontSize={{ base: "lg", lg: "xl" }} mt="10px" color={'gray.700'} paddingLeft={3}>{t('question_text')}
                    </Text>
                    <Box flex={"1"}></Box>
                </Flex>
                <Textarea
                    display={tabIndex != 0}
                    onChange={(e) => {
                        dispatch(updateQuestion(e.target.value));
                    }} value={toeflData.question}
                    height="150px"
                    placeholder={toeflData.tabIndex == 2 ?
                        t("toefl_placeholder_message1")
                        : ""}
                    spellcheck="false"
                ></Textarea>
                <Text
                    textAlign={'justify'}
                    whiteSpace='pre-line'
                    color="gray.600"
                    px="20px"
                    display={tabIndex == 0 ? "block" : "none"}
                    fontSize={'md'}>
                    {toeflData.question}
                </Text>
                <Flex mt={"10px"} display={isDiscussion ? "block" : "none"}>

                    <Flex flexDirection={"row"} margin={0}>
                        <Text fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{t('writing_discussion')}
                        </Text>
                        <Box flex={"1"}></Box>
                    </Flex>
                    <ConversationTable dataType={'toeflData'} />

                </Flex>
                <Flex flexDirection={"row"} margin={0} display={isGeneration ? "block" : "none"}>
                    <Text mt={"10px"} fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{t('target_score')}
                    </Text>
                    <Box flex={"1"}></Box>
                </Flex>
                <RadioGroup
                    display={isGeneration ? "block" : "none"}
                    onChange={(v) => { dispatch(updateTargetScore(v)) }}
                    value={toeflData.targetScore}>
                    <Stack direction='row' spacing={10} ml={'20px'}>
                        <Radio value='20'>20</Radio>
                        <Radio value='25'>25</Radio>
                        <Radio value='30'>30</Radio>
                    </Stack>
                </RadioGroup>
                <Flex flexDirection={"row"} margin={0} display={isGeneration ? "block" : "none"}>
                    <Text mt={"10px"} fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{t('direction')}
                    </Text>
                    <Box flex={"1"}></Box>
                </Flex>
                <Textarea
                    onChange={(e) => {
                        dispatch(updateAnswerOutline(e.target.value))
                    }} value={toeflData.answerOutline}
                    display={isGeneration ? "block" : "none"}
                    height="200px"
                    placeholder={toeflData.tabIndex == 2 ?
                        t("toefl_placeholder_message2")
                        : ""}
                    spellcheck="false"
                ></Textarea>
                <Flex>
                    <Flex flexDirection={"row"} margin={0} display={showAnswer ? "block" : "none"}>
                        <Text mt={"10px"} fontWeight={600} fontSize="xl" mt="10px" color={'gray.700'} paddingLeft={3}>{t('answer')}
                        </Text>
                        <Box flex={"1"}></Box>
                    </Flex>
                </Flex>
                <Textarea
                    rows={6}
                    onChange={(e) => {
                        dispatch(updateAnswer(e.target.value));
                    }}
                    value={toeflData.answer}
                    display={showAnswer ? "block" : "none"}
                    height="400px"
                    spellcheck="false"
                ></Textarea>
                <Flex mt="10px">
                    <Box flex="1"></Box>
                    <Badge
                        variant='outline' colorScheme='blue' mr="10px"
                        display={toeflData.selectedExampleLevel && toeflData.section == "writing" ? "block" : "none"}
                    >
                        {t("example_essay")}
                    </Badge>
                    <Text align={"right"} color={'grey'} display={(isWriting && isGrading) ? "block" : "none"} p="0" m="0">words: {wordCount}</Text>
                </Flex>

            </>
        );
    }

    return (
        <Flex flexDirection={"column"} width="full">
            <Flex flexDirection="row">
                <Center>
                    <Text mt={"10px"} fontWeight={600} fontSize={{ base: "lg", lg: "xl" }} mt="10px" color={'gray.700'} paddingLeft={3}>{t('task_choice')}
                    </Text>
                </Center>
                <Center px = '20px'>
                    <Select value={toeflData.taskName} onChange={(e) => {
                        dispatch(updateTaskName(e.target.value));
                    }}>
                        {getTaskOptions()}
                    </Select>
                </Center>
                <Box flex={1}></Box>
                {/*<Button fontSize={'sm'}*/}
                {/*        px="25px"*/}
                {/*        ml={4}*/}
                {/*        mt={isGeneration ? 4 : 0}*/}
                {/*        colorScheme="gray"*/}
                {/*        _hover={{*/}
                {/*            bg: 'blue.500',*/}
                {/*        }}*/}
                {/*        _focus={{*/}
                {/*            bg: 'blue.500',*/}
                {/*        }}*/}
                {/*        display={!isCustomGrading}*/}
                {/*        onClick={onOpen}>{!isSelectTpo ? (!isDiscussion ? `${t('select_tpo')}` : `${t('select_question')}`) : (!isDiscussion ? "TPO" : `${t('select_question')}`) + toeflData.tpoNumber + " ▼"}</Button>*/}
            </Flex>
            {!isPractice &&
                <Box>
                    {getTaskPanel()}
                    <input id='fileInput' type="file" accept=".m4a,audio/*" onChange={handleUpload} rounded={'full'} style={{ display: 'none' }}
                    />
                    <Center mt={"20px"}>
                        <Flex>
                            <ReactAudioPlayer
                                style={{ display: showRecordingFunctions ? "inline-block" : "none" }}
                                src={toeflData.audioFile}
                                controls={toeflData.audioFile ? true : false}
                            />
                            <Flex flexDirection="column">
                                <Badge
                                    variant='outline' colorScheme='blue' ml="10px" mt="20px"
                                    display={toeflData.selectedExampleLevel && toeflData.section == "speaking" ? "block" : "none"}
                                >
                                    {toeflData.audioFile ? t("example_recording") : t("example_recording_loading")}
                                </Badge>
                                <Box flex="1"></Box>
                            </Flex>
                        </Flex>
                    </Center>
                    <Flex ml="10px"
                          display={(toeflData.section == "speaking" || toeflData.section == "writing") && toeflData.taskName == "task1" && toeflData.tpoNumber == 1 ? "block" : "none"}>
                        <Center>
                            <NewUserPopover></NewUserPopover>
                        </Center>
                        <Center>
                            <TestSelectionModal></TestSelectionModal>
                        </Center>
                    </Flex>
                    <Center>
                        <Flex marginTop={5} flexDirection={{ base: "column", md: "row" }} spacing='15px'>
                            <Button
                                fontSize={'sm'}
                                rounded={'full'}
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                _focus={{
                                    bg: 'blue.500',
                                }}
                                minWidth={120}
                                onClick={() => {
                                    const fileInput = document.getElementById('fileInput');
                                    fileInput.click();
                                }
                                }
                                display={showRecordingFunctions ? "block" : "none"}
                            >{t('upload_recording')}</Button>
                            <Box width="10px" height="10px" display={showRecordingFunctions ? "block" : "none"}></Box>
                            <Button fontSize={'sm'}
                                    rounded={'full'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    _focus={{
                                        bg: 'blue.500',
                                    }}
                                    minWidth={120}
                                    onClick={handleRecord}
                                    display={showRecordingFunctions ? "block" : "none"}
                            >{isRecording ? t('stop_record') + formatRecordingSeconds(toeflData.recordingSeconds) : t('start_record')}</Button>
                            <Box width="10px" height="10px" display={showRecordingFunctions ? "block" : "none"}></Box>
                            <Button fontSize={'sm'}
                                    rounded={'full'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    _focus={{
                                        bg: 'blue.500',
                                    }}
                                    minWidth={120}
                                    onClick={() => {
                                        if (toeflData.isStreaming) {
                                            dispatch(abortGeneration());
                                        } else {
                                            if (!validateEvaluationInput()) {
                                                console.log("Evaluation input is not valid")
                                                return;
                                            }

                                            if (shouldShowChargeAlert()) {
                                                onChargeAlertOpen();
                                            } else {
                                                handleSubmit();
                                            }
                                        }
                                    }}>{toeflData.isStreaming ? t('stop_record') : (isGeneration ? t('generate') : t('grading'))}</Button>
                            <AlertDialog
                                isOpen={isChargeAlertOpen}
                                leastDestructiveRef={cancelRef}
                                onClose={onChargeAlertClose}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                            {t("kind_notice")}
                                        </AlertDialogHeader>
                                        <AlertDialogBody>
                                            <Text>
                                                {t("this_will_cost")} {getChargeAmount()}{t("credit")}
                                            </Text>
                                            <Text fontSize={'sm'} style={{ color: "#2e8ae6", fontWeight: "bold" }}>
                                                <a href='/purchase'>{t("purchase_credits")}</a>
                                            </Text>
                                            <Checkbox
                                                isChecked={silenceChargeAlert}
                                                onChange={(e) => setSilenceChargeAlert(e.target.checked)}
                                            >{t("dont_remind_me_again")}</Checkbox>

                                        </AlertDialogBody>
                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onChargeAlertClose}>
                                                {t("cancel")}
                                            </Button>
                                            <Button colorScheme='green' onClick={() => {
                                                onChargeAlertClose();
                                                handleSubmit();
                                            }} ml={3}>
                                                {t("continue")}
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </Flex>
                    </Center>
                    <Center mt="10px">
                        <ShareResultButton visible={markdownResponse.result && !toeflData.isStreaming}></ShareResultButton>
                    </Center>
                    <ResponsePanel></ResponsePanel>
                </Box>}
            {isSelectTpo && isPractice &&
                <Box>
                    <Center mt={"20px"}>
                        <Button onClick={handleRouteChange}>{t("start_mock_test")} {!isDiscussion ? "TPO" : "Practice Question"} {toeflData.tpoNumber}</Button>
                    </Center>
                </Box>}
        </Flex>

    );
}
