// import
import React, {Component} from 'react';
import ToeflView from "views/Dashboard/ToeflView.js";
import HistoryView from "views/Dashboard/HistoryView.js";
import IeltsView from 'views/Dashboard/IeltsView';
import IeltsPracticeView from 'views/Dashboard/IeltsPracticeView';
import TPOPracticeView from 'views/Dashboard/TPOPracticeView';
import {
    PersonIcon,
    ClockIcon, CartIcon
} from "components/Icons/Icons";
import AccountView from "views/Dashboard/AccountView.js";
import Purchase from "./views/Dashboard/Purchase";
import {FiFileText, FiMic, FiLock } from "react-icons/fi";
import {FiPenTool} from "react-icons/fi";
import AdminView from "./views/Dashboard/AdminView";
import SchoolAssistantView from "./views/Dashboard/SchoolAssistantView";
import CampusCertification from "./views/Dashboard/CampusCertification";
import CampusCertificationGuidance from "./views/Dashboard/CampusCertificationGuidance";

var dashRoutes = [
    {
        path: "/speaking/practice",
        name: "toefl_speaking",
        icon: <FiMic color='inherit'/>,
        component: TPOPracticeView,
        layout: "/toefl",
        visibility: "never",
    },
    {
        path: "/writing/practice",
        name: "toefl_writing",
        icon: <FiMic color='inherit'/>,
        component: TPOPracticeView,
        layout: "/toefl",
        visibility: "never",
    },
    {
        path: "/speaking/corpus_generation",
        name: "toefl_speaking",
        icon: <FiMic color='inherit'/>,
        component: TPOPracticeView,
        layout: "/toefl",
        visibility: "never",
    },
    {
        path: "/writing/corpus_generation",
        name: "toefl_writing",
        icon: <FiMic color='inherit'/>,
        component: TPOPracticeView,
        layout: "/toefl",
        visibility: "never",
    },

    {
        path: "/speaking",
        name: "toefl_speaking",
        icon: <FiMic color='inherit'/>,
        component: ToeflView,
        layout: "/toefl",
        visibility: "only_toefl",
        // alertText: 'question_bank_updated'
    },
    {
        path: "/writing",
        name: "toefl_writing",
        icon: <FiFileText color='inherit'/>,
        component: ToeflView,
        layout: "/toefl",
        visibility: "only_toefl",
        // alertText: 'question_bank_updated'
    },
    {
        path: "/speaking/practice",
        name: "ielts_speaking",
        icon: <FiMic color='inherit'/>,
        component: IeltsPracticeView,
        layout: "/ielts",
        visibility: "never",
    },
    {
        path: "/writing/practice",
        name: "ielts_writing",
        icon: <FiMic color='inherit'/>,
        component: IeltsPracticeView,
        layout: "/ielts",
        visibility: "never",
    },
    {
        path: "/speaking",
        name: "ielts_speaking",
        icon: <FiMic color='inherit'/>,
        component: IeltsView,
        layout: "/ielts",
        visibility: "only_ielts",
        // alertText: 'question_bank_updated'
    },
    {
        path: "/writing",
        name: "ielts_writing",
        icon: <FiFileText color='inherit'/>,
        component: IeltsView,
        layout: "/ielts",
        visibility: "only_ielts",
        // alertText: 'question_bank_updated'
    },
    {
        path: "/share/:gid",
        name: "share",
        icon: <CartIcon color='inherit'/>,
        component: HistoryView,
        layout: null,
        visibility: "never",
    },
    // {
    //     path: "/ielts_speaking/practice",
    //     hideInNav: true,
    //     name: "IELTS Speaking Practice",
    //     icon: <FiFileText color='inherit'/>,
    //     component: IeltsPracticeView,
    //     layout: "/page",
    // },
    // {
    //     path: "/ielts_speaking",
    //     name: "IELTS Speaking",
    //     icon: <FiFileText color='inherit'/>,
    //     component: IeltsView,
    //     layout: "/page",
    // },
    {
        path: "/history",
        name: "grading_history",
        icon: <ClockIcon color='inherit'/>,
        component: HistoryView,
        layout: "/toefl",
        visibility: "only_toefl",
    },
    {
        path: "/history",
        name: "grading_history",
        icon: <ClockIcon color='inherit'/>,
        component: HistoryView,
        layout: "/ielts",
        visibility: "only_ielts",
    },
    // Disable PS Assistant for now
    /*
    {
        path: "/ps_assistant",
        name: "ps_assistant",
        icon: <FiPenTool color='inherit'/>,
        component: SchoolAssistantView,
        layout: null,
        visibility: "always",
    },
    */
    {
        name: "account",
        category: "account",
        state: "pageCollapse",
        views: [
            {
                path: "/admin",
                name: "admin",
                icon: <FiLock color='inherit'/>,
                component: AdminView,
                layout: null,
                visibility: "admin_only",
            },
            {
                path: "/purchase",
                name: "purchase_credits",
                icon: <CartIcon color='inherit'/>,
                component: Purchase,
                layout: null,
                visibility: "always",
            },
            {
                path: "/account",
                name: "my_account",
                icon: <PersonIcon color='inherit'/>,
                component: AccountView,
                layout: null,
                visibility: "always",
                // alertText: 'my_account_alert'
            },
            // {
            //     path: "/campus_certification",
            //     name: "campus_certify",
            //     icon: <PersonIcon color='inherit'/>,
            //     component: CampusCertification,
            //     layout: null,
            //     visibility: "never",
            // },
            // {
            //     path: "/campus_certification_guidance",
            //     name: "campus_certify_guidance",
            //     icon: <PersonIcon color='inherit'/>,
            //     component: CampusCertificationGuidance,
            //     layout: null,
            //     visibility: "never",
            // },
        ],
    }
];
export default dashRoutes;
